import type { RouteRecordRaw } from 'vue-router'
import PassThrough from '@/components/layout/PassThrough.vue'

export const tasksRoutes: Array<RouteRecordRaw> = [
  {
    name: 'base.tasks.index',
    path: '',
    component: () => import('@/components/views/tasks/TasksIndex.vue'),
    redirect: { name: 'base.tasks.kanban' },
    children: [
      {
        name: 'base.tasks.kanban',
        path: 'kanban',
        component: () => import('@/components/views/tasks/TasksKanban.vue'),
      },
      {
        name: 'base.tasks.list',
        path: 'list',
        component: () => import('@/components/views/tasks/TasksList.vue'),
      },
    ],
  },
  {
    name: 'base.tasks.emptyRoute',
    path: 'emptyRoute',
    component: PassThrough,
    redirect: { name: 'base.tasks.index' },
  },
  {
    name: 'base.tasks.settings',
    path: 'settings',
    props: {
      title: 'Settings',
      menuTitle: 'Tasks settings',
    },
    component: () => import('@/components/views/tasks/settings/TasksSettings.vue'),
    redirect: { name: 'base.tasks.settings.index' },
    children: [
      {
        name: 'base.tasks.settings.detailed.emptyRoute',
        path: 'emptyRoute',
        component: PassThrough,
        redirect: { name: 'base.tasks.settings.detailed' },
      },
      {
        name: 'base.tasks.settings.index',
        path: '',
        props: {
          title: 'Boards',
        },
        component: () => import('@/components/views/tasks/settings/TasksSettingsIndex.vue'),
      },
      {
        name: 'base.tasks.settings.detailed',
        path: 'detailed',
        props: {
          title: 'Marketing',
        },
        component: () => import('@/components/views/tasks/settings/TasksSettingsDetailed.vue'),
      },
      {
        name: 'base.tasks.settings.detailed.stages',
        path: 'detailed/stages',
        props: {
          title: 'Stages',
        },
        component: () => import('@/components/views/tasks/settings/TasksSettingsStages.vue'),
      },
    ],
  },
]
