import type { RouteRecordRaw } from 'vue-router'
import RouterViewInheritSlots from '@/components/router/RouterViewInheritSlots.vue'

export const adminRoutes: Array<RouteRecordRaw> = [
  {
    name: 'base.admin.roles',
    path: 'roles',
    props: {
      title: 'Roles',
    },
    component: () => import('@/components/views/admin/workspace/AdminWorkspaceRoles.vue'),
  },
  {
    name: 'base.admin.users',
    path: 'users',
    meta: {
      showUsers: true,
    },
    props: {
      title: 'Users',
    },
    redirect: { name: 'base.admin.users.active' },
    component: () => import('@/components/views/admin/workspace/users/AdminWorkspaceUsers.vue'),
    children: [
      {
        name: 'base.admin.users.active',
        path: 'active',
        component: () => import('@/components/views/admin/workspace/users/AdminWorkspaceUsersActive.vue'),
      },
      {
        name: 'base.admin.users.invited',
        path: 'invited',
        component: () => import('@/components/views/admin/workspace/users/AdminWorkspaceUsersActive.vue'),
      },
    ],
  },
  {
    name: 'base.admin.subAccounts',
    path: 'sub-accounts',
    meta: {
      showSubAccounts: true,
    },
    props: {
      title: 'Sub-accounts',
    },
    component: RouterViewInheritSlots,
    redirect: { name: 'base.admin.subAccounts.main' },
    children: [
      {
        name: 'base.admin.subAccounts.main',
        path: '',
        props: {
          title: 'Sub-accounts',
        },
        component: () => import('@/components/views/admin/workspace/subAccounts/AdminWorkspaceSubAccounts.vue'),
      },
      {
        name: 'base.admin.subAccounts.active',
        path: 'active',
        props: {
          title: 'Sub-accounts',
          subAccounts: true,
        },
        component: () => import('@/components/views/admin/workspace/users/AdminWorkspaceUsersActive.vue'),
      },
      {
        name: 'base.admin.subAccounts.details',
        path: 'details',
        props: {
          title: 'Sub-accounts details',
        },
        redirect: { name: 'base.admin.subAccounts.details.overview' },
        component: () => import('@/components/views/admin/workspace/subAccounts/AdminWorkspaceSubAccountsDetails.vue'),
        children: [
          {
            name: 'base.admin.subAccounts.details.overview',
            path: 'overview',
            props: {
              title: 'Sub-accounts details',
            },
            component: () => import('@/components/views/admin/workspace/subAccounts/AdminWorkspaceSubAccountsDetailsOverview.vue'),
          },
          {
            name: 'base.admin.subAccounts.details.audit',
            path: 'audit',
            props: {
              title: 'Sub-accounts details',
            },
            component: () => import('@/components/views/admin/workspace/userDetails/AuditLogs.vue'),
          },
        ],
      },
    ],
  },
  {
    name: 'base.admin.teamMembers',
    path: 'team-members',
    props: {
      title: 'Team members',
    },
    component: () => import('@/components/views/admin/workspace/AdminWorkspaceTeamMembers.vue'),
  },
  {
    name: 'base.admin.overview',
    path: 'overview',
    component: () => import('@/components/views/admin/Admin.vue'),
    redirect: { name: 'base.admin.overview.index' },
    children: [
      {
        name: 'base.admin.overview.index',
        path: 'index',
        props: {
          title: 'Billing overview',
        },
        component: () => import('@/components/views/admin/billing/AdminBillingIndex.vue'),
      },
      {
        name: 'base.admin.overview.plan',
        path: 'plan',
        props: {
          title: 'Billing overview',
        },
        component: () => import('@/components/views/admin/billing/AdminBillingOverview.vue'),
      },
      {
        name: 'base.admin.overview.payAsYouGo',
        path: 'pay-as-you-go',
        props: {
          title: 'Billing pay as you go',
        },
        component: () => import('@/components/views/admin/billing/AdminBillingPayAsYouGo.vue'),
      },
    ],
  },
  {
    name: 'base.admin.plans',
    path: 'plans',
    component: () => import('@/components/views/admin/plans/AdminPlans.vue'),
  },
  {
    name: 'base.admin.userDetails',
    path: 'users/:id',
    component: () => import('@/components/views/admin/workspace/userDetails/UserDetails.vue'),
    redirect: to => ({ name: 'base.admin.userDetails.profile', params: { id: to.params.id } }),
    meta: {
      hideInMenu: true,
      title: 'User Details',
    },
    children: [
      {
        name: 'base.admin.userDetails.profile',
        path: 'profile',
        meta: {
          title: 'Profile',
        },
        component: () => import('@/components/views/admin/workspace/userDetails/Profile.vue'),
      },
      {
        name: 'base.admin.userDetails.auditLogs',
        path: 'audit-logs',
        meta: {
          title: 'Audit logs',
        },
        component: () => import('@/components/views/admin/workspace/userDetails/AuditLogs.vue'),
      },
      {
        name: 'base.admin.userDetails.outboundSMS',
        path: 'outbound-sms',
        meta: {
          title: 'Outbound SMS',
        },
        component: () => import('@/components/views/admin/workspace/userDetails/OutboundSMS.vue'),
      },
      {
        name: 'base.admin.userDetails.inboundSMS',
        path: 'inbound-sms',
        meta: {
          title: 'Inbound SMS',
        },
        component: () => import('@/components/views/admin/workspace/userDetails/InboundSMS.vue'),
      },
    ],
  },
  {
    name: 'base.admin.roles.details',
    path: 'roles/details',
    props: {
      title: 'Roles',
    },
    component: () => import('@/components/views/admin/workspace/AdminWorkspaceRolesDetails.vue'),
  },
  {
    name: 'base.admin.settings',
    path: 'settings',
    props: {
      title: 'Settings',
    },
    component: () => import('@/components/views/admin/workspace/AdminWorkspaceSettings.vue'),
  },
  {
    name: 'base.admin.auditLogs',
    path: 'audit-logs',
    props: {
      title: 'Audit logs',
    },
    component: () => import('@/components/views/reporting/auditLogs/ReportingAuditLogs.vue'),
  },
  {
    name: 'base.admin.invoices',
    path: 'invoices',
    props: {
      title: 'Invoices',
    },
    component: () => import('@/components/views/admin/billing/AdminInvoices.vue'),
  },
  {
    name: 'base.admin.statements',
    path: 'statements',
    props: {
      title: 'Statements',
    },
    component: () => import('@/components/views/reporting/statements/ReportingStatements.vue'),
  },
  {
    name: 'base.admin.sso',
    path: 'settings/sso',
    props: {
      title: 'Single Sign-On',
    },
    component: () => import('@/components/views/admin/workspace/AdminWorkspaceSSO.vue'),
  },
  {
    name: 'base.admin.data',
    path: 'settings/data',
    props: {
      title: 'Data retention',
    },
    component: () => import('@/components/views/admin/workspace/AdminWorkspaceData.vue'),
  },
  {
    name: 'base.admin.workspaceSecurity',
    path: 'workspace-security',
    props: {
      title: 'Workspace Security',
    },
    component: () => import('@/components/views/admin/workspace/security/AdminWorkspaceSecurity.vue'),
    redirect: { name: 'base.admin.workspaceSecurity.index' },
    children: [
      {
        name: 'base.admin.workspaceSecurity.index',
        path: '',
        props: {
          title: 'Workspace Security',
        },
        component: () => import('@/components/views/admin/workspace/security/AdminWorkspaceSecurityIndex.vue'),
      },
      {
        name: 'base.admin.twoFactorAuthenticationFlow',
        path: 'two-factor-authentication',
        meta: {
          title: 'Two-factor Authentication Flow',
        },
        component: () => import('@/components/views/admin/workspace/security/TwoFactorAuthenticationFlow.vue'),
      },
    ],
  },
  {
    name: 'base.admin.myAccount',
    path: 'my-account',
    props: {
      title: 'My account',
    },
    component: () => import('@/components/views/admin/workspace/AdminPersonalMyAccount.vue'),
  },
  {
    name: 'base.admin.security',
    path: 'security',
    props: {
      title: 'Security',
    },
    component: () => import('@/components/views/admin/workspace/AdminPersonalSecurity.vue'),
  },
  {
    name: 'base.admin.notifications',
    path: 'notifications',
    props: {
      title: 'Notification settings',
    },
    component: () => import('@/components/views/admin/workspace/AdminPersonalNotifications.vue'),
  },
]
