import type { PageContentSidebarItem, PageContentSidebarTabsItem } from '@/definitions/_layout/pageContent/sidebar/types'

export const campaignsSmsSidebarRoutes: PageContentSidebarItem[] = [
  {
    title: 'Sent',
    icon: 'send',
    number: '240',
    route: { name: 'base.campaigns.sms.sent' },
  },
  {
    title: 'Drafts',
    icon: 'tmi-draft',
    number: '8',
    route: { name: 'base.campaigns.sms.drafts' },
  },
  {
    title: 'Scheduled',
    icon: 'schedule',
    number: '16',
    route: { name: 'base.campaigns.sms.scheduled' },
  },
  {
    title: 'Calendar',
    icon: 'event',
    route: { name: 'base.campaigns.sms.calendar' },
  },
  {
    title: 'Templates',
    icon: 'assignment',
    number: '28',
    route: { name: 'base.campaigns.sms.templates' },
  },
]

export const campaignsSmsLinksSidebarRoutes: PageContentSidebarItem[] = [
  {
    title: 'Numbers',
    icon: 'send',
    route: { name: 'base.chats.settings.channels' },
    linkIcon: true,
  },
  {
    title: 'Compliance',
    icon: 'account_balance',
    route: { name: 'base.chats.settings.10dlc' },
    linkIcon: true,
  },
  {
    title: 'SMS surveys',
    icon: 'tmi-sitemap',
    route: { name: 'base.services.smsSurveys' },
    linkIcon: true,
  },
  {
    title: 'History logs',
    icon: 'question_answer',
    route: { name: 'base.history.sentSms' },
    linkIcon: true,
  },
]

export const campaignsEmailsSidebarRoutes: PageContentSidebarItem[] = [
  {
    title: 'Sent',
    icon: 'send',
    number: '140',
    route: { name: 'base.campaigns.email.sent' },
  },
  {
    title: 'Drafts',
    icon: 'tmi-draft',
    number: '8',
    route: { name: 'base.campaigns.email.drafts' },
  },
  {
    title: 'Scheduled',
    icon: 'schedule',
    number: '16',
    route: { name: 'base.campaigns.email.scheduled' },
  },
  {
    title: 'Calendar',
    icon: 'event',
    route: { name: 'base.campaigns.email.calendar' },
  },
  {
    title: 'Templates',
    icon: 'assignment',
    number: '28',
    route: { name: 'base.campaigns.email.templates' },
  },
]

export const campaignsEmailsLinksSidebarRoutes: PageContentSidebarItem[] = [
  {
    title: 'Domains',
    icon: 'language',
    // route: { name: 'base.chats.settings.10dlc' },
    linkIcon: true,
  },
  {
    title: 'Compliance',
    icon: 'account_balance',
    // route: { name: 'base.chats.settings.10dlc' },
    linkIcon: true,
  },
  {
    title: 'History logs',
    icon: 'question_answer',
    route: { name: 'base.history.sentSms' },
    linkIcon: true,
  },
]

export const campaignsSidebarRoutes: PageContentSidebarTabsItem[] = [
  {
    tab: 'SMS',
    route: { name: 'base.campaigns.sms' },
    children: campaignsSmsSidebarRoutes,
  },
  {
    tab: 'Email',
    route: { name: 'base.campaigns.email' },
    children: campaignsEmailsSidebarRoutes,
  },
]
