import type { RouteRecordRaw } from 'vue-router'

export const survey: RouteRecordRaw[] = [
  {
    name: 'surveyPage',
    path: '/survey-page',
    meta: {
      title: 'Survey page',
    },
    component: () => import('@/components/views/tickets/settings/SurveyPage.vue'),
  },
  {
    name: 'survey',
    path: '/survey',
    meta: {
      title: 'Survey',
    },
    component: () => import('@/components/containers/SinglePageContainer.vue'),
    children: [
      {
        name: 'survey.plans',
        path: '/plans',
        meta: {
          title: 'Textmagic Plans',
        },
        component: () => import('@/components/views/plans/Plans.vue'),
      },
      {
        name: 'survey.plans.orderSuccess',
        path: '/plans/success',
        meta: {
          title: 'Textmagic Plans order success',
        },
        component: () => import('@/components/views/plans/PlansOrderSuccess.vue'),
      },
      {
        name: 'survey.plans.orderSuccessBank',
        path: '/plans/successBank',
        meta: {
          title: 'Textmagic Plans order success bank',
        },
        component: () => import('@/components/views/plans/PlansOrderSuccessBank.vue'),
      },
      {
        name: 'survey.plans.successPending',
        path: '/plans/orderPending',
        meta: {
          title: 'Textmagic Plans order pending',
        },
        component: () => import('@/components/views/plans/PlansOrderPending.vue'),
      },
    ],
  },
]
