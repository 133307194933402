import type { RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import PassThrough from '@/components/layout/PassThrough.vue'
import { baseRoutes } from '@/router/routes/base'
import { uiRoutes } from '@/router/routes/ui'
import { authRoutes } from '@/router/routes/auth'
import { survey } from '@/router/routes/survey'
import { otherPages } from '@/router/routes/otherPages'
import { makeRouteTitles } from '@/services/string'

export const routes: Array<RouteRecordRaw> = [
  {
    name: 'root',
    path: '/',
    redirect: { name: 'base' },
    component: PassThrough,
    children: [
      ...baseRoutes,
    ],
  },
  ...authRoutes,
  ...uiRoutes,
  ...survey,
  ...otherPages,
  {
    name: 'sitemap',
    path: '/sitemap',
    redirect: '/sitemap/base.gettingStarted',
    component: PassThrough,
    children: [
      {
        path: '/sitemap/:page',
        component: () => import('@/components/views/sitemap/Sitemap.vue'),
      },
    ],
  },
]

makeRouteTitles(routes)

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL || '/'),
  routes,
})

router.afterEach(to => {
  const title = to.meta.title
  document.title = 'Textmagic' + (title ? `: ${title}` : '')
})

export default router
