import type { RouteRecordName, RouteRecordRaw } from 'vue-router'

export type HighlightChunk = {
  isHighlighted: boolean;
  text: string;
}

export const checkMatch = (text: string | undefined, search: string): boolean => {
  if (!text) {
    return false
  }

  return text.toLowerCase().indexOf(search.toLowerCase()) !== -1
}

export const doHighlight = (text: string, search: string): HighlightChunk[] => {
  const chunks = []
  let position = text.toLowerCase().indexOf(search.toLowerCase())
  let newText = ''

  if (search.length === 0) {
    return [{ isHighlighted: false, text }]
  }

  while (text.length) {
    if (position === -1) {
      chunks.push({ isHighlighted: false, text })
      break
    }

    if (position === 0) {
      newText = text.substring(search.length)
      chunks.push({ isHighlighted: true, text: text.substring(0, search.length) })
    } else {
      const highlightedText = text.substring(position, position + search.length)
      const notHighlightedText = text.substring(0, position)

      newText = text.substring(position + search.length)
      chunks.push({ isHighlighted: false, text: notHighlightedText })
      chunks.push({ isHighlighted: true, text: highlightedText })
    }

    text = newText
    position = text.toLowerCase().indexOf(search.toLowerCase())
  }

  return chunks
}

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const transformPathToString = (inputString?: RouteRecordName): string => {
  if (!inputString || typeof inputString === 'symbol') return ''
  // Удаляем префикс "base.", если он есть
  if (inputString.startsWith('base.')) {
    inputString = inputString.slice(5) // Удаляем первые 5 символов ('base.')
  }
  // Заменяем "." на " " (точка на пробел)
  inputString = inputString.replace(/\./g, ' ')

  // Заменяем CamelCase на человекочитаемый текст
  inputString = inputString.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase()

  return capitalizeFirstLetter(inputString)
}

export const makeRouteTitles = (routesArr: Array<RouteRecordRaw>): void => {
  routesArr.forEach(route => {
    if (!route.meta || !route.meta.title) {
      route.meta = route.meta || {}
      route.meta.title = transformPathToString(route.name)
    }

    if (route.children?.length) {
      makeRouteTitles(route.children)
    }
  })
}
