import { useTicketsGettingStarted } from '@/compositions/tickets/useTicketsGettingsStarted'
import { computed } from 'vue'
import type { PageContentSidebarItem, PageContentSidebarItemAction } from '@/definitions/_layout/pageContent/sidebar/types'

const { getOpenLink, getSimulateTicketState } = useTicketsGettingStarted()

export const ticketsGettingsStartedSidebarRoute: PageContentSidebarItem[] = [
  {
    itemType: 'link',
    closable: true,
    title: 'Getting started',
    route: { name: 'base.tickets.main.gettingStarted' },
  },
]

export const actions: PageContentSidebarItemAction[] = [
  { text: 'Edit view', icon: 'edit', name: 'edit' },
  { text: 'Duplicate view', icon: 'tmi-duplicate', name: 'duplicate' },
  { text: 'Delete view', icon: 'delete', name: 'delete' },
]

export const ticketsSidebarRoutes: PageContentSidebarItem[] = [
  {
    itemType: 'title',
    title: 'Shared views',
    collapsible: true,
    actions: actions,
  },
  {
    itemType: 'link',
    title: 'Open email requests',
    number: '31',
    route: { name: 'base.tickets.main.index' },
    actions: actions,
    emoji: 'speaking',
  },
  {
    itemType: 'link',
    title: 'BANK TRANSFERS received',
    number: '0',
    lightItem: true,
    route: { name: 'base.tickets.main.bankTransfers' },
    actions: actions,
    emoji: 'bank',
  },
  {
    itemType: 'link',
    title: 'Open call requests',
    number: '1',
    route: { name: 'base.tickets.main.openCallRequests' },
    actions: actions,
    emoji: 'phone',
  },
  {
    itemType: 'link',
    title: 'AU PENDING cases',
    number: '4',
    route: { name: 'base.tickets.main.auPendingCases' },
    actions: actions,
    emoji: 'flag',
  },
  {
    itemType: 'link',
    title: 'PENDING 10DLC + TF cases',
    lightItem: true,
    number: '0',
    route: { name: 'base.tickets.main.pendingDlc' },
    actions: actions,
    emoji: 'lock',
  },
  {
    itemType: 'link',
    title: 'Open SENDER ID notifications',
    number: '0',
    lightItem: true,
    route: { name: 'base.tickets.main.openSenderId' },
    actions: actions,
    emoji: 'mobile',
  },
  {
    itemType: 'link',
    title: 'Recent order notifications',
    number: '12.2K',
    route: { name: 'base.tickets.main.recentOrder' },
    actions: actions,
    emoji: 'bell',
  },
  {
    itemType: 'link',
    title: 'Support tickets',
    number: '2.8K',
    route: { name: 'base.tickets.main.emptyRoute' },
    actions: actions,
    emoji: 'jacket',
  },
  {
    itemType: 'link',
    title: 'Free trials',
    number: '1.4K',
    route: { name: 'base.tickets.main.emptyRoute' },
    actions: actions,
    emoji: 'casino',
  },
  {
    itemType: 'link',
    title: 'All resolved tickets',
    number: '133.8K',
    route: { name: 'base.tickets.main.emptyRoute' },
    actions: actions,
    emoji: 'check',
  },
  {
    itemType: 'link',
    title: 'Rated tickets from the last 7 days',
    number: '14',
    route: { name: 'base.tickets.main.emptyRoute' },
    actions: actions,
    emoji: 'star',
  },
  {
    itemType: 'link',
    title: 'Spam',
    number: '12',
    route: { name: 'base.tickets.main.emptyRoute' },
    actions: actions,
    emoji: 'stop',
  },
  {
    itemType: 'link',
    title: 'Deleted',
    number: '36',
    route: { name: 'base.tickets.main.emptyRoute' },
    actions: actions,
    emoji: 'trash-can',
  },
]

export const ticketsSystemSidebarRoutes: PageContentSidebarItem[] = [
  {
    itemType: 'title',
    title: 'Personal views',
    collapsible: true,
  },
  {
    itemType: 'link',
    title: 'Drafts',
    number: '7',
    actions: actions,
    route: { name: 'base.tickets.main.drafts' },
    emoji: 'edit',
  },
  {
    itemType: 'link',
    title: 'Assigned to me',
    number: '16',
    route: { name: 'base.tickets.main.assignedToMe' },
    actions: actions,
    emoji: 'visibility',
  },
  {
    itemType: 'link',
    title: 'Custom view',
    number: '14',
    route: { name: 'base.tickets.main.customView' },
    actions: actions,
    emoji: 'thinking',
  },
]

export const ticketsStartingPersonalSidebarRoutes: PageContentSidebarItem[] = [
  {
    itemType: 'title',
    title: 'Personal views',
    collapsible: true,
  },
]

export const ticketsStartingSidebarRoutes = computed((): PageContentSidebarItem[] => [
  {
    itemType: 'title',
    title: 'Shared views',
    collapsible: true,
  },
  getOpenLink.value,
  {
    itemType: 'link',
    title: 'Pending',
    number: '0',
    emoji: 'time',
    lightItem: true,
    actions: actions,
    route: { name: 'base.tickets.main.emptyRoute' },
  },
  {
    itemType: 'link',
    title: 'Unassigned',
    number: '0',
    lightItem: true,
    actions: actions,
    emoji: 'eyes',
    route: { name: 'base.tickets.main.emptyRoute' },
  },
  {
    itemType: 'link',
    title: 'Solved',
    number: '0',
    lightItem: true,
    emoji: 'check',
    actions: actions,
    route: { name: 'base.tickets.main.emptyRoute' },
  },
  {
    itemType: 'link',
    title: 'Rated tickets',
    number: '0',
    lightItem: true,
    actions: actions,
    emoji: 'star',
    route: { name: 'base.tickets.main.emptyRoute' },
  },
  {
    itemType: 'link',
    title: 'Recently updated',
    number: getSimulateTicketState.value ? '1' : '0',
    lightItem: !getSimulateTicketState.value,
    actions: actions,
    emoji: 'calendar',
    route: { name: 'base.tickets.main.emptyRoute' },
  },
  {
    itemType: 'link',
    title: 'All tickets',
    number: getSimulateTicketState.value ? '1' : '0',
    lightItem: !getSimulateTicketState.value,
    emoji: 'mail-box',
    actions: actions,
    route: { name: 'base.tickets.main.emptyRoute' },
  },
  {
    itemType: 'link',
    title: 'Spam',
    emoji: 'stop',
    number: '0',
    actions: actions,
    lightItem: true,
    route: { name: 'base.tickets.main.emptyRoute' },
  },
  {
    itemType: 'link',
    title: 'Deleted',
    number: '0',
    lightItem: true,
    emoji: 'trash-can',
    actions: actions,
    route: { name: 'base.tickets.main.emptyRoute' },
  },
])
