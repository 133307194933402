import type { RouteRecordRaw } from 'vue-router'

export const reportingRoutes: Array<RouteRecordRaw> = [
  {
    name: 'base.reporting.overview',
    path: 'overview',
    props: {
      title: 'Overview',
    },
    component: () => import('@/components/views/reporting/overview/ReportingOverview.vue'),
    redirect: { name: 'base.reporting.overview.textMessages' },
    children: [
      {
        name: 'base.reporting.overview.textMessages',
        path: 'text-messages',
        component: () => import('@/components/views/reporting/overview/ReportingOverviewTextMessages.vue'),
      },
      {
        name: 'base.reporting.overview.calls',
        path: 'calls',
        component: () => import('@/components/views/reporting/overview/ReportingOverviewCalls.vue'),
      },
      {
        name: 'base.reporting.overview.liveChats',
        path: 'live-chats',
        component: () => import('@/components/views/reporting/overview/ReportingOverviewLiveChats.vue'),
      },
      {
        name: 'base.reporting.overview.socialChannels',
        path: 'social-channels',
        component: () => import('@/components/views/reporting/overview/ReportingOverviewSocialChannels.vue'),
      },
    ],
  },
  {
    name: 'base.reporting.messages',
    path: 'messages',
    props: {
      title: 'Messages',
    },
    component: () => import('@/components/views/reporting/messages/ReportingMessages.vue'),
    redirect: { name: 'base.reporting.messages.full' },
    children: [
      {
        name: 'base.reporting.messages.full',
        path: '',
        component: () => import('@/components/views/reporting/messages/ReportingMessagesFull.vue'),
      },
      {
        name: 'base.reporting.messages.year',
        path: ':year',
        component: () => import('@/components/views/reporting/messages/ReportingMessagesYear.vue'),
      },
      {
        name: 'base.reporting.messages.month',
        path: ':year/:month',
        component: () => import('@/components/views/reporting/messages/ReportingMessagesMonth.vue'),
      },
    ],
  },
  {
    name: 'base.reporting.calls',
    path: 'calls',
    props: {
      title: 'Calls',
    },
    component: () => import('@/components/views/reporting/calls/ReportingCalls.vue'),
    redirect: { name: 'base.reporting.calls.full' },
    children: [
      {
        name: 'base.reporting.calls.full',
        path: '',
        component: () => import('@/components/views/reporting/calls/ReportingCallsFull.vue'),
      },
      {
        name: 'base.reporting.calls.year',
        path: ':year',
        component: () => import('@/components/views/reporting/calls/ReportingCallsYear.vue'),
      },
      {
        name: 'base.reporting.calls.month',
        path: ':year/:month',
        component: () => import('@/components/views/reporting/calls/ReportingCallsMonth.vue'),
      },
    ],
  },
  {
    name: 'base.reporting.auditLogs',
    path: 'audit-logs',
    props: {
      title: 'Audit logs',
    },
    component: () => import('@/components/views/reporting/auditLogs/ReportingAuditLogs.vue'),
  },
  {
    name: 'base.reporting.subAccounts',
    path: 'sub-accounts',
    props: {
      title: 'Users',
    },
    component: () => import('@/components/views/reporting/subAccounts/ReportingSubAccounts.vue'),
  },
  {
    name: 'base.reporting.numbers',
    path: 'numbers',
    props: {
      title: 'Numbers',
    },
    component: () => import('@/components/views/reporting/numbers/ReportingNumbers.vue'),
  },
  {
    name: 'base.reporting.helpdesk',
    path: 'helpdesk',
    props: {
      title: 'Helpdesk',
    },
    component: () => import('@/components/views/reporting/helpdesk/ReportingHelpdesk.vue'),
  },
  {
    name: 'base.reporting.tasks',
    path: 'tasks',
    props: {
      title: 'Tasks',
    },
    component: () => import('@/components/views/reporting/tasks/ReportingTasks.vue'),
  },
  {
    name: 'base.reporting.deals',
    path: 'deals',
    props: {
      title: 'Deals',
    },
    component: () => import('@/components/views/reporting/deals/ReportingDeals.vue'),
  },
]
