import type { PageContentSidebarItem } from '@/definitions/_layout/pageContent/sidebar/types'

export const servicesSidebarRoutes: PageContentSidebarItem[] = [
  {
    itemType: 'link',
    title: 'Sender settings',
    route: { name: 'base.services.senderSettings' },
  },
  {
    itemType: 'link',
    title: 'Bring your own CPaaS',
    route: { name: 'base.services.byoc' },
  },
  {
    itemType: 'link',
    title: 'Email to SMS',
    route: { name: 'base.services.emailToSms' },
  },
  {
    itemType: 'link',
    title: 'API',
    route: { name: 'base.services.api' },
  },
  {
    itemType: 'link',
    title: 'Automation rules',
    route: { name: 'base.services.automationRules.rules' },
  },
  {
    itemType: 'link',
    title: 'SMS Surveys',
    route: { name: 'base.services.smsSurveys' },
  },
  {
    itemType: 'link',
    title: 'Text to Speech',
    route: { name: 'base.services.textToSpeech' },
  },
  {
    itemType: 'link',
    title: 'Forms',
    route: { name: 'base.services.forms' },
  },
  {
    itemType: 'link',
    title: 'Downloads',
    route: { name: 'base.services.downloads' },
  },
  {
    itemType: 'title',
    title: 'Lookup',
  },
  {
    itemType: 'link',
    title: 'Carrier lookup',
    route: { name: 'base.services.carrierLookup' },
  },
  {
    itemType: 'link',
    title: 'Email lookup',
    route: { name: 'base.services.emailLookup' },
  },
]
