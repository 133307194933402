import contactsLists from '@/mock/contactsLists.json'
import contactsSegments from '@/mock/contactsSegments.json'
import contactsOrganizations from '@/mock/contactsOrganizations.json'
import contactsAllContacts from '@/mock/contactsAllContacts.json'
import contactsCustomFields from '@/mock/contactsCustomFields.json'
import contactsImport from '@/mock/contactsImport.json'
import contactsImportDetails from '@/mock/contactsImportDetails.json'
import contactsListsDetails from '@/mock/contactsListsDetails.json'
import contactsBlocked from '@/mock/contactsBlocked.json'
import contactsUnsubscribed from '@/mock/contactsUnsubscribed.json'
import userActivities from '@/mock/userActivities.json'
import contactsLarge from '@/mock/contactsLarge.json'
import tasks from '@/mock/tasks.json'
import scheduledUpcomingPaused from '@/mock/scheduledUpcomingPaused.json'
import users from '@/mock/users.json'
import usersPreview from '@/mock/usersPreview.json'
import chats from '@/mock/chats.json'
import widgetChat from '@/mock/widgetChat.json'
import chatsEmail from '@/mock/chatsEmail.json'
import chatsEmailLive from '@/mock/chatsEmailLive.json'
import forwardCalls from '@/mock/forwardCalls.json'
import outboundCalls from '@/mock/outboundCalls.json'
import inboundCalls from '@/mock/inboundCalls.json'
import auditLogs from '@/mock/auditLogs.json'
import composeAudioRecordings from '@/mock/composeAudioRecordings.json'
import historySMS from '@/mock/historySMS.json'
import allowedEmails from '@/mock/allowedEmails.json'
import servicesCountries from '@/mock/servicesCountries.json'
import servicesNumbers from '@/mock/servicesNumbers.json'
import distributionLists from '@/mock/distributionLists.json'
import servicesSender from '@/mock/servicesSender.json'
import servicesForms from '@/mock/servicesForms.json'
import servicesFormsSubscribe from '@/mock/servicesFormsSubscribe.json'
import templatesAllCategories from '@/mock/templatesAllCategories.json'
import subAccounts from '@/mock/subAccounts.json'
import subAccountsImport from '@/mock/subAccountsImport.json'
import subAccountsClosed from '@/mock/subAccountsClosed.json'
import automationRules from '@/mock/automationRules.json'
import automationKeywords from '@/mock/automationKeywords.json'
import automationLogs from '@/mock/automationLogs.json'
import invoices from '@/mock/invoices.json'
import loginHistory from '@/mock/loginHistory.json'
import usersLoginHistory from '@/mock/usersLoginHistory.json'
import smsSurvey from '@/mock/smsSurvey.json'
import templatesMarketing from '@/mock/templatesMarketing.json'
import templatesSales from '@/mock/templatesSales.json'
import campaignsEmails from '@/mock/campaignsEmails.json'
import campaignsDraft from '@/mock/campaignsDraft.json'
import campaignsScheduled from '@/mock/campaignsScheduled.json'
import subAccountsImportDetails from '@/mock/subAccountsImportDetails.json'
import templatesSupport from '@/mock/templatesSupport.json'
import templatesGeneral from '@/mock/templatesGeneral.json'
import statements from '@/mock/statements.json'
import reportingSubAccounts from '@/mock/reportingSubAccounts.json'
import reportingNumbers from '@/mock/reportingNumbers.json'
import carrierLookupBulk from '@/mock/carrierLookupBulk.json'
import carrierLookupHistory from '@/mock/carrierLookupHistory.json'
import emailLookupBulk from '@/mock/emailLookupBulk.json'
import emailLookupHistory from '@/mock/emailLookupHistory.json'
import apiKeys from '@/mock/apiKeys.json'
import apiVerify from '@/mock/apiVerify.json'
import reportingCategories from '@/mock/reportingCategories.json'
import reportingAuditLogs from '@/mock/reportingAuditLogs.json'
import smsSurveyReplies from '@/mock/smsSurveyReplies.json'
import outboundSMS from '@/mock/outboundSMS.json'
import inboundSMS from '@/mock/inboundSMS.json'
import numberChannels from '@/mock/numberChannels.json'
import dlcNumbers from '@/mock/dlcNumbers.json'
import tollFreeNumbers from '@/mock/tollFreeNumbers.json'
import chatsFilteredViews from '@/mock/chatsFilteredViews.json'
import dialpadContacts from '@/mock/dialpadContacts.json'
import dialpadCalls from '@/mock/dialpadCalls.json'
import usersAdmin from '@/mock/usersAdmin.json'
import files from '@/mock/files.json'
import homeRecentlyViewed from '@/mock/homeRecentlyViewed.json'
import tickets from '@/mock/tickets.json'
import ticketsDetailsMessages from '@/mock/ticketsDetailsMessages.json'
import ticketDetails from '@/mock/ticketDetails.json'
import ticketRatings from '@/mock/ticketRatings.json'
import customFields from '@/mock/customFields.json'
import deals from '@/mock/deals.json'
import recipientsList from '@/mock/recipientsList.json'
import agentPerfomance from '@/mock/agentPerfomance.json'
import campaignsSentMessages from '@/mock/campaignsSentMessages.json'
import campaignsEmailSentMessages from '@/mock/campaignsEmailSentMessages.json'

const tableDataMap: { [key: string]: { [id: string]: any } } = {
  recipientsList,
  contactsLists,
  campaignsSentMessages,
  campaignsEmailSentMessages,
  deals,
  agentPerfomance,
  contactsSegments,
  tickets,
  ticketsDetailsMessages,
  tasks,
  ticketDetails,
  ticketRatings,
  contactsOrganizations,
  dialpadCalls,
  dialpadContacts,
  chatsFilteredViews,
  tollFreeNumbers,
  servicesFormsSubscribe,
  dlcNumbers,
  contactsAllContacts,
  numberChannels,
  servicesForms,
  contactsCustomFields,
  loginHistory,
  usersLoginHistory,
  contactsImport,
  templatesGeneral,
  contactsImportDetails,
  contactsListsDetails,
  contactsBlocked,
  contactsUnsubscribed,
  scheduledUpcomingPaused,
  userActivities,
  contactsLarge,
  reportingCategories,
  users,
  usersAdmin,
  usersPreview,
  chats,
  widgetChat,
  forwardCalls,
  outboundCalls,
  auditLogs,
  inboundCalls,
  subAccountsImportDetails,
  composeAudioRecordings,
  chatsEmail,
  chatsEmailLive,
  historySMS,
  allowedEmails,
  servicesCountries,
  servicesNumbers,
  distributionLists,
  servicesSender,
  templatesAllCategories,
  subAccounts,
  subAccountsImport,
  subAccountsClosed,
  automationRules,
  automationKeywords,
  automationLogs,
  invoices,
  smsSurvey,
  templatesMarketing,
  templatesSales,
  templatesSupport,
  campaignsEmails,
  campaignsDraft,
  campaignsScheduled,
  statements,
  reportingSubAccounts,
  reportingNumbers,
  carrierLookupBulk,
  carrierLookupHistory,
  emailLookupBulk,
  emailLookupHistory,
  apiKeys,
  apiVerify,
  reportingAuditLogs,
  smsSurveyReplies,
  outboundSMS,
  inboundSMS,
  files,
  homeRecentlyViewed,
  customFields,
}

export function getTableData(entity: string, getObject = false): Array<any> | any {
  const tableData = tableDataMap[entity]
  if (!tableData) throw new Error(`${entity} table not exist in tableDataMap`)

  if (getObject) return tableData
  return Object.entries(tableData)
    .map(([id, value]) => ({
      ...value,
      id,
    }))
}

export function getRecord(entity: string, id: string): any {
  const tableData = tableDataMap[entity]

  return tableData[id]
}
