import type { Moment, unitOfTime } from 'moment'
import moment from 'moment'
import { capitalize } from '@/services/utils'

export const formatDate = (date: Moment | string | Date, format?: string): string => {
  return moment(date).format(format || 'D MMM, h:mm a')
}

export const toMoment = (rawDate: string): Moment | null => {
  if (rawDate && rawDate.length > 0) {
    return moment(rawDate)
  }

  return null
}

export const strToDate = (date: string, format: string): Date => {
  return moment(date, format).toDate()
}

export const isInFormat = (date: string, format: string): boolean => {
  return moment(date, format).format(format) === date
}
export const calendar = (date: string): string => {
  return moment(date)
    .calendar(null, {
      sameDay: '[Today] h:mm a',
      lastDay: '[Yesterday] h:mm a',
      lastWeek: 'D MMM h:mm a',
      sameElse: 'D MMM h:mm a',
    })
}

type tillDueDateObj = {
  hours: number,
  isToday: boolean,
  isTomorrow: boolean,
  isYesterday: boolean,
  isSameYear: boolean,
  fromNow: string,
  isOverdue: boolean,
}
export const isYesterday = (date: Date | string): boolean => {
  const yesterday = moment().add(-1, 'days')
  return moment(date).isSame(yesterday, 'day')
}
export const isLast7Days = (date: Date | string): boolean => {
  const week = moment().add(-7, 'days')
  return moment(date).isSameOrAfter(week, 'day')
}
export const isLast30Days = (date: Date | string): boolean => {
  const month = moment().add(-30, 'days')
  return moment(date).isSameOrAfter(month, 'day')
}
export const isSameYear = (date: Date | string): boolean => {
  const today = moment()
  return moment(date).isSame(today, 'year')
}
export const isToday = (date: Date | string): boolean => {
  const today = moment()
  return moment(date).isSame(today, 'day')
}

export const tillDueDate = (date: Date | string): tillDueDateObj => {
  const dueDate = moment(date)
  const today = moment()
  const tomorrow = moment().add(1, 'days')

  return {
    hours: dueDate.diff(today, 'hours'),
    isToday: isToday(date),
    isTomorrow: dueDate.isSame(tomorrow, 'day'),
    isYesterday: isYesterday(date),
    isSameYear: isSameYear(date),
    fromNow: dueDate.fromNow(),
    isOverdue: (dueDate.diff(today) < 0),
  }
}

export const format = (date: Moment | string | Date, format: string | undefined): any => {
  return moment(date).format(format)
}

const oneDay = 24 * 60 * 60 * 1000
export const formatUntilOneDay = (date: string, format = 'D MMM h:mm a', withoutSuffix = false): string => {
  const isLessOneDay = Math.abs(+new Date() - +new Date(date)) < oneDay
  if (isLessOneDay) {
    return capitalize(moment(date).fromNow(withoutSuffix))
  }
  return formatDate(date, format)
}

export const add = (date: Date, amount: number, unit: unitOfTime.Base): Moment => {
  return moment(date).add(amount, unit)
}

export default {
  format,
  isInFormat,
  strToDate,
  formatDate,
  toMoment,
  calendar,
  add,
  tillDueDate,
}
