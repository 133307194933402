import type { RouteRecordRaw } from 'vue-router'

export const gettingStartedRoutes: Array<RouteRecordRaw> = [
  {
    name: 'base.gettingStarted.us',
    path: 'us',
    component: () => import('@/components/views/gettingStarted/GettingStartedUs.vue'),
  },
  {
    name: 'base.gettingStarted.ca',
    path: 'ca',
    component: () => import('@/components/views/gettingStarted/GettingStartedCa.vue'),
  },
  {
    name: 'base.gettingStarted.uk',
    path: 'uk',
    component: () => import('@/components/views/gettingStarted/GettingStartedUk.vue'),
  },
  {
    name: 'base.gettingStarted.au',
    path: 'au',
    component: () => import('@/components/views/gettingStarted/GettingStartedAu.vue'),
  },
  {
    name: 'base.gettingStarted.other',
    path: 'other',
    component: () => import('@/components/views/gettingStarted/GettingStartedOther.vue'),
  },
]
