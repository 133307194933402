import type { RouteRecordRaw } from 'vue-router'

export const otherPages: Array<RouteRecordRaw> = [
  {
    name: 'viewOriginalEmail',
    path: '/view-original-email',
    component: () => import('@/components/views/chats/singleWindow/ViewOriginalEmail.vue'),
  },
  {
    name: 'chatWindow',
    path: '/chat-window',
    component: () => import('@/components/views/chats/singleWindow/OpenInWindow.vue'),
  },
  {
    name: 'ticketDetailsWindow',
    path: '/ticket-details-window',
    component: () => import('@/components/views/tickets/TicketsDetailsWindow.vue'),
  },
  {
    name: 'introduction',
    path: '/introduction',
    component: () => import('@/components/views/introduction/Introduction.vue'),
  },
  {
    name: '10dlcPrivacyPolicy',
    path: '/10dls-sms-privacy-policy',
    component: () => import('@/components/views/other/10DlcPrivacyPolicy.vue'),
  },
  {
    name: '10dlcTerms',
    path: '/10dls-sms-terms',
    component: () => import('@/components/views/other/10DlcSmsTerms.vue'),
  },
  {
    name: 'privacyPolicySample',
    path: '/privacy-policy-sample',
    component: () => import('@/components/views/other/PrivacyPolicySample.vue'),
  },
  {
    name: 'termsSample',
    path: '/terms-sample',
    component: () => import('@/components/views/other/TermsSample.vue'),
  },
]
