import type { PageContentSidebarItem, PageContentSidebarItemAction } from '@/definitions/_layout/pageContent/sidebar/types'

export const contactsSidebarRoutes: PageContentSidebarItem[] = [
  {
    itemType: 'link',
    title: 'All contacts',
    number: '3.4K',
    route: { name: 'base.contacts.allContacts' },
  },
  {
    itemType: 'link',
    title: 'Lists',
    number: '13',
    route: { name: 'base.contacts.lists' },
  },
  {
    itemType: 'link',
    title: 'Segments',
    number: '11',
    route: { name: 'base.contacts.segments' },
  },
  {
    itemType: 'link',
    title: 'Organizations',
    number: '32',
    route: { name: 'base.contacts.organizations' },
  },
  {
    itemType: 'link',
    title: 'Imports',
    number: '12',
    route: { name: 'base.contacts.import' },
  },
  // {
  //   itemType: 'link',
  //   title: 'Blocked',
  //   number: '12',
  //   route: { name: 'base.contacts.blocked' },
  // },
  {
    itemType: 'link',
    title: 'Unsubscribed',
    number: '32',
    route: { name: 'base.contacts.unsubscribed' },
  },
]

export const pinActions: PageContentSidebarItemAction[] = [
  {
    icon: 'visibility',
    text: 'View',
    name: 'view',
  },
  {
    icon: 'tmi-unpin',
    text: 'Unpin',
    name: 'unpin',
  },
]

export const contactsSidebarPinRoutes: PageContentSidebarItem[] = [
  {
    title: 'Bug test list 13-Jan',
    number: '294',
    route: { name: 'base.contacts.lists.details', params: { id: 1 } },
    actions: pinActions,
  },
  {
    title: 'USA client base',
    number: '4.5K',
    route: { name: 'base.contacts.lists.details', params: { id: 2 } },
    actions: pinActions,
  },
  {
    title: 'Europe team',
    number: '445',
    route: { name: 'base.contacts.lists.details', params: { id: 3 } },
    actions: pinActions,
  },
  {
    title: 'Ralph Edwards',
    route: { name: 'base.contacts.lists.details', params: { id: 4 } },
    actions: pinActions,
  },
  {
    title: 'eBay',
    route: { name: 'base.contacts.organizationDetails', params: { id: 1 } },
    actions: pinActions,
  },
]
