import type { PageContentSidebarItem } from '@/definitions/_layout/pageContent/sidebar/types'

export const historySidebarRoutes: PageContentSidebarItem[] = [
  {
    itemType: 'link',
    title: 'Outbound SMS',
    number: '3.2k',
    route: { name: 'base.history.sentSms' },
  },
  {
    itemType: 'link',
    number: '1.2k',
    title: 'Inbound SMS',
    route: { name: 'base.history.receivedSms' },
  },
  {
    itemType: 'link',
    number: '12',
    title: 'Forwarded calls',
    route: { name: 'base.history.forwardCalls' },
  },
  {
    itemType: 'link',
    number: '234',
    title: 'Outbound calls',
    route: { name: 'base.history.outboundCalls' },
  },
  {
    itemType: 'link',
    number: '54',
    title: 'Inbound calls',
    route: { name: 'base.history.inboundCalls' },
  },
]
