import type { PageContentSidebarItem, PageContentSidebarItemAction } from '@/definitions/_layout/pageContent/sidebar/types'

const actions: PageContentSidebarItemAction[] = [
  { text: 'Edit board', icon: 'edit', name: 'edit' },
  { text: 'View details', icon: 'visibility', name: 'viewDetails' },
  { text: 'Archive', icon: 'tmi-inbox-open', name: 'archive' },
]

export const tasksSidebarRoutes: PageContentSidebarItem[] = [
  {
    itemType: 'link',
    title: 'Personal',
    number: '12',
    route: { name: 'base.tasks.index' },
    actions: actions,
    emoji: 'smiling',
  },
  {
    itemType: 'link',
    title: 'Marketing',
    number: '8',
    route: { name: 'base.tasks.emptyRoute' },
    actions: actions,
    emoji: 'rocket',
  },
  {
    itemType: 'link',
    title: 'Support',
    number: '2',
    route: { name: 'base.tasks.emptyRoute' },
    actions: actions,
    emoji: 'headset',
  },
  {
    itemType: 'link',
    title: 'Sales',
    number: '72',
    route: { name: 'base.tasks.emptyRoute' },
    actions: actions,
    emoji: 'cash',
  },
  {
    itemType: 'link',
    title: 'Dev',
    number: '4',
    route: { name: 'base.tasks.emptyRoute' },
    actions: actions,
    emoji: 'tools',
  },
  {
    itemType: 'link',
    title: 'Management',
    number: '16',
    route: { name: 'base.tasks.emptyRoute' },
    actions: actions,
    emoji: 'mac-working',
  },
  {
    itemType: 'link',
    title: 'Design',
    number: '28',
    route: { name: 'base.tasks.emptyRoute' },
    actions: actions,
    emoji: 'flower',
  },
]

export const tasksSettingsSidebarRoutes: PageContentSidebarItem[] = [
  {
    itemType: 'link',
    title: 'Personal',
    route: { name: 'base.tasks.settings.detailed.emptyRoute' },
  },
  {
    itemType: 'link',
    title: 'Marketing',
    route: { name: 'base.tasks.settings.detailed' },
  },
  {
    itemType: 'link',
    title: 'Support',
    route: { name: 'base.tasks.settings.detailed.emptyRoute' },
  },
  {
    itemType: 'link',
    title: 'Sales',
    route: { name: 'base.tasks.settings.detailed.emptyRoute' },
  },
  {
    itemType: 'link',
    title: 'Dev',
    route: { name: 'base.tasks.settings.detailed.emptyRoute' },
  },
  {
    itemType: 'link',
    title: 'Management',
    route: { name: 'base.tasks.settings.detailed.emptyRoute' },
  },
  {
    itemType: 'link',
    title: 'Design',
    route: { name: 'base.tasks.settings.detailed.emptyRoute' },
  },
]

export const tasksSettingsSidebarAllRoute: PageContentSidebarItem[] = [
  {
    itemType: 'link',
    title: 'All boards',
    route: { name: 'base.tasks.settings.index' },
  },
]
export const tasksSettingsSidebarArchiveRoute: PageContentSidebarItem[] = [
  {
    itemType: 'link',
    title: 'Onboarding',
    route: { name: 'base.tasks.settings.detailed.emptyRoute' },
  },
]
