export const removeProtocolFromUrl = (url: string): string => url.replace(/http(s)?(:)?(\/\/)?|(\/\/)?(www\.)?/g, '')

// Regular expressions
export const time12Regexp = /^(0?[1-9]|1[0-2]):([0-5]\d)\s?((?:A|P)\.?M\.?)$/i
export const domainRegexp = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
export const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const phoneRegexp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/
export const ipRegexp = /^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/
// Regular expressions end

export const capitalize = (str: string): string => str.replace(/^(.)/, (q) => q.toUpperCase())

export const lightenDarkenColor = (color: string, percent: number): string => {
  const num = parseInt(color.replace('#', ''), 16)
  const R = Math.round((num >> 16) + ((255 - (num >> 16)) * (percent / 100)))
  const B = Math.round((num >> 8 & 0x00FF) + ((255 - (num >> 8 & 0x00FF)) * (percent / 100)))
  const G = Math.round((num & 0x0000FF) + (((255 - (num & 0x0000FF)) * (percent / 100))))
  return '#' + (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1)
}
export const getStyle = (el: HTMLElement, property: string): string => window.getComputedStyle(el).getPropertyValue(property)
export const formatNumber = (num: number): string => new Intl.NumberFormat('en-US').format(num)
export const numberToPrice = (number: number, currency = '$'): string => {
  const formatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 })
  return `${currency}${formatter.format(number)}`
}

export const getTextWidth = (text: string, fontSize = '14px', fontWeight = 400, letterSpacing = 2.1): number => {
  const font = fontWeight + ' ' + fontSize + ' "-apple-system, BlinkMacSystemFont, Inter, Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
  const canvas = document.createElement('canvas')

  const context = canvas.getContext('2d')
  context!.font = font

  const letterSpacingOffset = text.length * letterSpacing

  return Math.round(context!.measureText(text).width + letterSpacingOffset)
}

export const handleDateFormatSymbol = (dateFormat: string, event: KeyboardEvent): boolean => {
  const symbolsRegExp = /\W/g
  const symbolsArr = dateFormat?.match(symbolsRegExp)
  const uniqSymbols = [...new Set(symbolsArr)]

  const charKey = event.key
  const exceptions = ['Backspace', 'Tab', ...uniqSymbols]
  if (!isNaN(Number.parseInt(charKey)) || charKey.includes('Arrow') || exceptions.includes(charKey)) {
    return true
  }
  event.preventDefault()
  return false
}

export const kebabToPascalCase = (str: string): string => (
  str.replace(/(^.|-.)/g, (q) => q.replace('-', '').toUpperCase())
)

/* quasar/src/utils/private/position-engine.js
  func getTargetRect necessary for correct positioning TmDropdown, TmTooltip with angle */
export const getTargetRect = (el: HTMLElement): {[key: string]: number} => ({
  top: 0,
  center: el.offsetHeight / 2,
  bottom: el.offsetHeight,
  left: 0,
  middle: el.offsetWidth / 2,
  right: el.offsetWidth,
})

/* quasar/src/utils/private/position-engine.js
  func getTargetRect necessary for correct positioning TmDropdown, TmTooltip with angle */
export const getAnchorRect = (el: HTMLElement, offset: [number, number]): {[key: string]: number} => {
  let { top, left, right, bottom, width, height } = el.getBoundingClientRect()

  if (offset !== undefined) {
    top -= offset[1]
    left -= offset[0]
    bottom += offset[1]
    right += offset[0]

    width += offset[0]
    height += offset[1]
  }

  return {
    top,
    left,
    right,
    bottom,
    width,
    height,
    middle: left + (right - left) / 2,
    center: top + (bottom - top) / 2,
  }
}

const setCss = (element: HTMLElement, css: Record<string, string>) => {
  const style = element.style as any

  Object.keys(css).forEach((prop) => {
    style[prop] = css[prop]
  })
}
export const getScrollbarWidth = ((): any => {
  let size: number
  return (scrollbarClass: string): number => { // sample our custom scrollbarClass: 'tm-default-scrollbar'
    if (size !== undefined) {
      return size
    }

    const inner = document.createElement('p')
    const outer = document.createElement('div')
    if (scrollbarClass) {
      outer.classList.add(scrollbarClass)
    }

    setCss(inner, {
      width: '100%',
      height: '200px',
    })
    setCss(outer, {
      position: 'absolute',
      top: '0px',
      left: '0px',
      visibility: 'hidden',
      width: '200px',
      height: '150px',
      overflow: 'hidden',
    })

    outer.appendChild(inner)

    document.body.appendChild(outer)

    const w1 = inner.offsetWidth
    outer.style.overflow = 'scroll'
    let w2 = inner.offsetWidth

    if (w1 === w2) {
      w2 = outer.clientWidth
    }

    outer.remove()
    size = w1 - w2

    return size
  }
})()

export const cropText = (msg: string, lng = 80): string => {
  const htmlLength = msg
    .replace(/<br>/g, ' ')
    .match(/(<.*?>)/g)?.join('').length || 0
  const length = lng + htmlLength

  return msg.length > length
    ? msg
      // replace new line/multiple spaces to space
      .replace(/\s{2,}|<br>/g, ' ')
      // truncate string \s can't end string
      .replace(new RegExp(`^(.{0,${length}}[^\\s])(.|\\s)*`, 'g'), '$1...')
    : msg
}

export const escapeText = (text: string): string => text.replace(/([^\w])/g, '\\$1')

export const filterItemsByValues = (query: string | RegExp, values: string[]): boolean => (
  (typeof query === 'string' ? new RegExp(escapeText(query), 'i') : query).test(values.join(''))
)

export const copyToClipboard = (str: string): void => {
  const fieldCopyText = document.createElement('input')
  fieldCopyText.setAttribute('value', str)
  document.querySelector('html')!.appendChild(fieldCopyText)
  fieldCopyText.select()
  document.execCommand('copy')
  fieldCopyText.remove()
}
export const getUid = (() => {
  let counter = 0
  return () => `uid-${counter++}`
})()

export const normalizeToInterval = (v: number, min: number, max: number): number => {
  if (max <= min) {
    return min
  }

  const size = (max - min + 1)

  let index = min + (v - min) % size
  if (index < min) {
    index = size + index
  }

  return index === 0 ? 0 : index // fix negative zero (-0) after remainder (%) example (-10 % 10) => -0
}

export const setAttrs = (element: HTMLElement, attrs: Record<string, string>): void => {
  Object.keys(attrs).forEach((prop) => {
    element.setAttribute(prop, attrs[prop])
  })
}

export const stopAndPrevent = (e: Event): void => {
  e.cancelable && e.preventDefault()
  e.stopPropagation()
}

export const randomIntFromInterval = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const formatTime = (time: number, format?: string, withZero?: boolean): string => {
  const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((time % (1000 * 60)) / 1000)

  const formats: { [key: string]: string } = {
    MM: withZero && minutes < 10 ? `0${minutes}` : minutes.toString(),
    SS: withZero && seconds < 10 ? `0${seconds}` : seconds.toString(),
  }

  return format ? format.replace(/(MM)|(SS)/g, (m: string) => formats[m]) : (minutes ? minutes + 'm ' : '') + seconds + 's '
}

export const randomDate = (start: Date, end: Date): Date => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))
}
