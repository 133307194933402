import type { RouteRecordRaw } from 'vue-router'
import PassThrough from '@/components/layout/PassThrough.vue'

export const otherRoutes: Array<RouteRecordRaw> = [
  {
    name: 'singlePages',
    path: '/',
    redirect: { name: 'singlePages.test' },
    meta: {
      title: 'Single pages',
    },
    component: () => import('@/components/containers/SinglePageContainer.vue'),
    children: [
      {
        name: 'singlePages.test',
        path: 'test',
        meta: {
          hideInMenu: true,
        },
        component: () => import('@/components/views/Test.vue'),
      },
      {
        name: 'base.services.numbers.tollFreeVerification.form',
        path: '/services/numbers/toll-free-verification/form',
        meta: {
          hideInMenu: true,
        },
        component: () => import('@/components/views/services/senderSettings/numbers/tollFreeVerificationForm/TollFreeVerificationForm.vue'),
      },
      {
        name: 'base.services.numbers.10DLC.registration',
        path: '/services/numbers/10dlc/registration',
        component: PassThrough,
        redirect: {
          name: 'base.services.numbers.10DLC.registration.step1',
        },
        meta: {
          hideInMenu: true,
        },
        children: [
          {
            name: 'base.services.numbers.10DLC.registration.step1',
            path: 'faq',
            component: () => import('@/components/views/services/senderSettings/numbers/dlcRegistration/ServicesNumberDlcRegistrationStep1.vue'),
          },
          {
            name: 'base.services.numbers.10DLC.registration.step2',
            path: 'brand',
            component: () => import('@/components/views/services/senderSettings/numbers/dlcRegistration/ServicesNumberDlcRegistrationStep2.vue'),
          },
          {
            name: 'base.services.numbers.10DLC.registration.step3',
            path: 'campaign',
            component: () => import('@/components/views/services/senderSettings/numbers/dlcRegistration/ServicesNumberDlcRegistrationStep3.vue'),
          },
        ],
      },
    ],
  },
  {
    name: 'base.widgetTestPage',
    path: '/widget-test-page-chat',
    component: () => import('@/components/views/home/WidgetTestPageChat.vue'),
  },
  {
    name: 'chatWidget',
    path: '/chat-widget',
    component: () => import('@/components/pages/chattingWidget/ChattingWidgetFullscreen.vue'),
  },
]
