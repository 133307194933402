import type { RouteRecordRaw } from 'vue-router'
import PassThrough from '@/components/layout/PassThrough.vue'
import { gettingStartedRoutes } from '@/router/routes/base/gettingStarted'
import { tasksRoutes } from '@/router/routes/base/tasks'
import { contactsRoutes } from '@/router/routes/base/contacts'
import { composeRoutes } from '@/router/routes/base/compose'
import { historyRoutes } from '@/router/routes/base/history'
import { servicesRoutes } from '@/router/routes/base/services'
import { reportingRoutes } from '@/router/routes/base/reporting'
import { campaignsRoutes } from '@/router/routes/base/campaigns'
import { contactsSidebarRoutes } from '@/router/sidebarRoutes/contactsRoutes'
import { historySidebarRoutes } from '@/router/sidebarRoutes/historyRoutes'
import { servicesSidebarRoutes } from '@/router/sidebarRoutes/servicesRoutes'
import { reportingSidebarRoutes } from '@/router/sidebarRoutes/reportingRoutes'
import { campaignsSidebarRoutes } from '@/router/sidebarRoutes/campaignsRoutes'
import { adminSidebarRoutes } from '@/router/sidebarRoutes/adminRoutes'
import { adminRoutes } from '@/router/routes/base/admin'
import { chatsRoutes } from '@/router/routes/base/chats'
import { tasksSidebarRoutes } from '@/router/sidebarRoutes/tasksRoutes'
import { ticketsRoutes } from '@/router/routes/base/tickets'
import { otherRoutes } from '@/router/routes/base/otherRoutes'
import { ticketsSidebarRoutes, ticketsSystemSidebarRoutes } from '@/router/sidebarRoutes/ticketsRoutes'
import { dealsRoutes } from '@/router/routes/base/deals'

export const baseRoute: RouteRecordRaw = {
  name: 'base',
  path: '/',
  redirect: { name: 'base.compose' },
  component: () => import('@/components/containers/RootContainer.vue'),
  children: [
    {
      name: 'base.gettingStarted',
      path: 'getting-started',
      meta: {
        icon: 'tmi-progress-wrench',
        hotKey: 'G',
        title: 'Getting started',
      },
      redirect: { name: 'base.getting-started.us' },
      component: PassThrough,
      children: gettingStartedRoutes,
    },
    {
      name: 'base.home',
      path: 'home',
      meta: {
        icon: 'home',
        hotKey: 'H',
        title: 'Home',
      },
      component: () => import('@/components/views/home/Home.vue'),
      redirect: { name: 'base.home.overview', params: { view: 'chats' } },
      children: [
        {
          name: 'base.home.overview',
          path: 'overview/:view?',
          meta: {
            title: 'Home overview',
          },
          component: () => import('@/components/views/home/HomeMain.vue'),
        },
        {
          name: 'base.home.contacts',
          path: 'contacts',
          meta: {
            title: 'Home contacts',
          },
          component: () => import('@/components/views/home/HomeContacts.vue'),
        },
        {
          name: 'base.home.organizations',
          path: 'organizations',
          meta: {
            title: 'Home organizations',
          },
          component: () => import('@/components/views/home/HomeOrganizations.vue'),
        },
      ],
    },
    {
      name: 'base.campaigns',
      path: 'campaigns',
      meta: {
        icon: 'tmi-campaign',
        hotKey: 'C',
        title: 'Campaigns',
        sidebarRoutes: campaignsSidebarRoutes,
      },
      redirect: { name: 'base.campaigns.sms' },
      component: () => import('@/components/views/campaigns/Campaigns.vue'),
      children: campaignsRoutes,
    },
    {
      name: 'base.compose',
      path: 'compose',
      meta: {
        icon: 'tmi-send',
        hotKey: 'C',
        title: 'Compose',
        hideInMenu: true,
      },
      redirect: { name: 'base.compose.message' },
      component: PassThrough,
      children: composeRoutes,
    },
    {
      name: 'base.chats',
      path: 'chats',
      meta: {
        icon: 'chat',
        hotKey: 'C',
        title: 'Messenger',
        counter: 2,
        tab: 'messenger',
      },
      redirect: { name: 'base.chats.main' },
      component: PassThrough,
      children: chatsRoutes,
    },
    {
      name: 'base.tickets',
      path: 'tickets',
      meta: {
        icon: 'email',
        hotKey: 'T',
        title: 'Helpdesk',
        hideBalance: true,
        sidebarRoutes: [
          ...ticketsSidebarRoutes,
          ...ticketsSystemSidebarRoutes,
        ],
      },
      component: PassThrough,
      redirect: { name: 'base.tickets.main' },
      children: ticketsRoutes,
    },
    {
      name: 'base.contacts',
      path: 'contacts',
      meta: {
        icon: 'tmi-people',
        hotKey: 'C',
        title: 'Contacts',
        sidebarRoutes: contactsSidebarRoutes,
      },
      redirect: { name: 'base.contacts.allContacts' },
      component: () => import('@/components/views/contacts/Contacts.vue'),
      children: contactsRoutes,
    },
    {
      name: 'base.tasks',
      path: 'tasks',
      meta: {
        icon: 'tmi-layers-tasks',
        hotKey: 'T',
        title: 'Tasks',
        sidebarRoutes: tasksSidebarRoutes,
      },
      redirect: { name: 'base.tasks.index' },
      component: () => import('@/components/views/tasks/Tasks.vue'),
      children: tasksRoutes,
    },
    {
      name: 'base.history',
      path: 'history',
      meta: {
        icon: 'history',
        hotKey: 'H',
        title: 'History',
        sidebarRoutes: historySidebarRoutes,
        hideInMenu: true,
      },
      component: () => import('@/components/views/history/History.vue'),
      children: historyRoutes,
    },
    {
      name: 'base.deals',
      path: 'deals',
      meta: {
        icon: 'tmi-sale',
        hotKey: 'D',
        title: 'Deals',
      },
      redirect: { name: 'base.deals.kanban' },
      component: () => import('@/components/views/deals/Deals.vue'),
      children: dealsRoutes,
    },
    {
      name: 'base.services',
      path: 'services',
      meta: {
        icon: 'widgets',
        hotKey: 'S',
        title: 'Services',
        sidebarRoutes: servicesSidebarRoutes,
      },
      redirect: { name: 'base.services.senderSettings' },
      component: () => import('@/components/views/services/Services.vue'),
      children: servicesRoutes,
    },
    {
      name: 'base.reporting',
      path: 'reporting',
      meta: {
        icon: 'assessment',
        hotKey: 'R',
        title: 'Reports',
        sidebarRoutes: reportingSidebarRoutes,
      },
      component: () => import('@/components/views/reporting/Reporting.vue'),
      children: reportingRoutes,
    },
    {
      name: 'base.admin',
      path: 'admin',
      meta: {
        icon: 'settings',
        hotKey: 'A',
        title: 'Admin',
        sidebarRoutes: adminSidebarRoutes,
        borderTop: true,
      },
      component: () => import('@/components/views/admin/Admin.vue'),
      redirect: { name: 'base.admin.roles' },
      children: adminRoutes,
    },
  ],
}

export const baseRoutes: Array<RouteRecordRaw> = [
  baseRoute,
  ...otherRoutes,
]
