import type { FlagCountry } from '@/definitions/shared/types'
import allCountries from './allCountries.json'

export const getCountriesList = (): FlagCountry[] => {
  return allCountries.map(item => {
    return {
      id: item.id.toLowerCase(),
      name: item.name,
    }
  })
}
