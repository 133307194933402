import type { RouteRecordRaw } from 'vue-router'
import PassThrough from '@/components/layout/PassThrough.vue'
import PassThroughAllSlots from '@/components/views/PassThroughAllSlots.vue'

export const ticketsRoutes: Array<RouteRecordRaw> = [
  {
    name: 'base.tickets.main',
    path: '',
    meta: {
      title: 'Helpdesk',
      sidebarCollapsed: true,
    },
    component: () => import('@/components/views/tickets/Tickets.vue'),
    redirect: { name: 'base.tickets.main.index' },
    children: [
      {
        name: 'base.tickets.main.emptyRoute',
        path: 'emptyRoute',
        component: PassThrough,
        redirect: { name: 'base.tickets.main.index' },
      },
      {
        name: 'base.tickets.main.index',
        path: 'open-email-requests',
        meta: {
          title: 'Open email requests',
        },
        props: {
          title: 'Open email requests',
        },
        component: () => import('@/components/views/tickets/TicketsIndex.vue'),
      },
      {
        name: 'base.tickets.main.bankTransfers',
        path: 'bank-transfers-received',
        meta: {
          title: 'Bank transfers received',
        },
        props: {
          ticketsItemsStartAt: 0,
          ticketsItemsEndAt: 0,
          title: 'Bank transfers received',
        },
        component: () => import('@/components/views/tickets/TicketsIndex.vue'),
      },
      {
        name: 'base.tickets.main.openCallRequests',
        path: 'open-call-requests',
        meta: {
          title: 'Open call requests',
        },
        props: {
          ticketsItemsStartAt: 0,
          ticketsItemsEndAt: 1,
          title: 'Open call requests',
        },
        component: () => import('@/components/views/tickets/TicketsIndex.vue'),
      },
      {
        name: 'base.tickets.main.auPendingCases',
        path: 'au-pending-cases',
        meta: {
          title: 'AU PENDING cases',
        },
        props: {
          ticketsItemsStartAt: 30,
          ticketsItemsEndAt: 34,
          title: 'AU PENDING cases',
        },
        component: () => import('@/components/views/tickets/TicketsIndex.vue'),
      },
      {
        name: 'base.tickets.main.pendingDlc',
        path: 'pending-10-dlc',
        meta: {
          title: 'PENDING 10DLC + TF cases',
        },
        props: {
          ticketsItemsStartAt: 0,
          ticketsItemsEndAt: 0,
          title: 'PENDING 10DLC + TF cases',
        },
        component: () => import('@/components/views/tickets/TicketsIndex.vue'),
      },
      {
        name: 'base.tickets.main.openSenderId',
        path: 'open-sender-id',
        meta: {
          title: 'Open SENDER ID notifications',
        },
        props: {
          ticketsItemsStartAt: 0,
          ticketsItemsEndAt: 0,
          title: 'Open SENDER ID notifications',
        },
        component: () => import('@/components/views/tickets/TicketsIndex.vue'),
      },
      {
        name: 'base.tickets.main.recentOrder',
        path: 'recent-order-notifications',
        meta: {
          title: 'Recent order notifications',
        },
        props: {
          ticketsItemsStartAt: 40,
          ticketsItemsEndAt: 100,
          title: 'Recent order notifications',
        },
        component: () => import('@/components/views/tickets/TicketsIndex.vue'),
      },
      {
        name: 'base.tickets.main.drafts',
        path: 'drafts',
        meta: {
          title: 'Drafts',
        },
        props: {
          ticketsItemsStartAt: 67,
          ticketsItemsEndAt: 74,
          title: 'Drafts',
          draftState: true,
        },
        component: () => import('@/components/views/tickets/TicketsIndex.vue'),
      },
      {
        name: 'base.tickets.main.assignedToMe',
        path: 'assigned-to-me',
        meta: {
          title: 'Assigned to me',
        },
        props: {
          ticketsItemsStartAt: 25,
          ticketsItemsEndAt: 43,
          title: 'Assigned to me',
        },
        component: () => import('@/components/views/tickets/TicketsIndex.vue'),
      },
      {
        name: 'base.tickets.main.customView',
        path: 'custom-view',
        meta: {
          title: 'Custom view',
        },
        props: {
          ticketsItemsStartAt: 33,
          ticketsItemsEndAt: 55,
          title: 'Custom view',
        },
        component: () => import('@/components/views/tickets/TicketsIndex.vue'),
      },
      {
        name: 'base.tickets.main.gettingStarted',
        path: 'getting-started',
        meta: {
          title: 'Getting started',
        },
        component: () => import('@/components/views/tickets/TicketsGettingStarted.vue'),
      },
      {
        name: 'base.tickets.details',
        path: 'details/:id',
        meta: {
          title: 'Tickets details',
          tab: 'ticket',
        },
        component: () => import('@/components/views/tickets/TicketsDetails.vue'),
      },
    ],
  },
  {
    name: 'base.tickets.settings',
    path: 'settings',
    meta: {
      title: 'Tickets settings',
    },
    component: () => import('@/components/views/tickets/settings/TicketsSettings.vue'),
    redirect: { name: 'base.tickets.settings.index' },
    children: [
      {
        name: 'base.tickets.settings.index',
        path: 'index',
        meta: {
          title: 'Tickets settings',
        },
        component: () => import('@/components/views/tickets/settings/TicketsSettingsIndex.vue'),
      },
      {
        name: 'base.tickets.settings.inboxes',
        path: 'inboxes',
        meta: {
          title: 'Connected inboxes',
        },
        component: PassThroughAllSlots,
        redirect: { name: 'base.tickets.settings.inboxes.index' },
        children: [
          {
            name: 'base.tickets.settings.inboxes.index',
            path: '',
            meta: {
              title: 'Connected inboxes',
            },
            component: () => import('@/components/views/tickets/settings/inboxes/Inboxes.vue'),
          },
          {
            name: 'base.tickets.settings.inboxes.detailed',
            path: 'detailed',
            meta: {
              title: 'Connected inbox',
            },
            component: () => import('@/components/views/tickets/settings/inboxes/InboxesDetailed.vue'),
          },
          {
            name: 'base.tickets.settings.inboxes.inboxFlow',
            path: 'inbox-flow',
            meta: {
              title: 'New inbox',
            },
            component: () => import('@/components/views/tickets/settings/inboxes/InboxFlow.vue'),
          },
        ],
      },
      {
        name: 'base.tickets.settings.autoResponder',
        path: 'autoResponder',
        meta: {
          title: 'Auto-responder',
        },
        component: () => import('@/components/views/tickets/settings/AutoResponder.vue'),
      },
      {
        name: 'base.tickets.settings.cannedResponses',
        path: 'canned-responses',
        meta: {
          title: 'Canned responses',
        },
        component: () => import('@/components/views/tickets/settings/CannedResponses.vue'),
      },
      {
        name: 'base.tickets.settings.statuses',
        path: 'statuses',
        meta: {
          title: 'Statuses',
        },
        component: () => import('@/components/views/tickets/settings/Statuses.vue'),
      },
      {
        name: 'base.tickets.settings.feedbackSurvey',
        path: 'feedbackSurvey',
        meta: {
          title: 'Feedback survey',
        },
        component: () => import('@/components/views/tickets/settings/FeedbackSurvey.vue'),
      },
      {
        name: 'base.tickets.settings.general',
        path: 'general',
        meta: {
          title: 'General',
        },
        component: () => import('@/components/views/tickets/settings/General.vue'),
      },
      {
        name: 'base.tickets.settings.dataFields',
        path: 'data-fields',
        meta: {
          title: 'Data fields',
        },
        component: () => import('@/components/views/tickets/settings/DataFields.vue'),
      },
      {
        name: 'base.tickets.settings.spamFilter',
        path: 'spamFilter',
        meta: {
          title: 'Spam filter',
        },
        component: () => import('@/components/views/tickets/settings/SpamFilter.vue'),
      },
      {
        name: 'base.tickets.settings.workspaceSubdomain',
        path: 'workspace-subdomain',
        meta: {
          title: 'Workspace subdomain',
        },
        redirect: { name: 'base.tickets.settings.index' },
        component: PassThrough,
      },
      {
        name: 'base.tickets.settings.filteredViews',
        path: 'filtered-views',
        meta: {
          title: 'Filtered views',
        },
        redirect: { name: 'base.tickets.settings.filteredViews.index' },
        component: PassThroughAllSlots,
        children: [
          {
            name: 'base.tickets.settings.filteredViews.index',
            path: '',
            meta: {
              title: 'Filtered views',
            },
            component: () => import('@/components/views/tickets/settings/filteredViews/TicketsSettingsFilteredViews.vue'),
          },
          {
            name: 'base.tickets.settings.filteredViews.detailed',
            path: 'detailed',
            meta: {
              title: 'Filtered views detailed',
            },
            component: () => import('@/components/views/tickets/settings/filteredViews/TicketsSettingsFilteredViewsDetailed.vue'),
          },
        ],
      },
      {
        name: 'base.tickets.settings.publicReply',
        path: 'public-reply',
        meta: {
          title: 'Public reply',
        },
        component: () => import('@/components/views/tickets/settings/PublicReply.vue'),
      },
    ],
  },
]
