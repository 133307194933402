import type { RouteRecordRaw } from 'vue-router'

export const historyRoutes: Array<RouteRecordRaw> = [
  {
    name: 'base.history.sentSms',
    path: 'sent-sms',
    props: {
      title: 'Outbound SMS',
    },
    component: () => import('@/components/router/RouterViewInheritSlots.vue'),
    redirect: { name: 'base.history.sentSms.main' },
    children: [
      {
        name: 'base.history.sentSms.main',
        path: 'main',
        props: {
          title: 'Outbound SMS',
        },
        component: () => import('@/components/views/history/sentSms/SentSms.vue'),
      },
      {
        name: 'base.history.sentSms.details',
        path: ':id/:subPage?',
        component: () => import('@/components/views/history/sentSms/SentSmsDetails.vue'),
      },
    ],
  },
  {
    name: 'base.history.receivedSms',
    path: 'received-sms',
    props: {
      title: 'Inbound SMS',
    },
    component: () => import('@/components/router/RouterViewInheritSlots.vue'),
    redirect: { name: 'base.history.receivedSms.main' },
    children: [
      {
        name: 'base.history.receivedSms.main',
        path: 'main',
        props: {
          title: 'Inbound SMS',
        },
        component: () => import('@/components/views/history/receivedSms/ReceivedSms.vue'),
      },
      {
        name: 'base.history.receivedSms.details',
        path: ':id',
        component: () => import('@/components/views/history/receivedSms/ReceivedSmsDetails.vue'),
      },
    ],
  },
  {
    name: 'base.history.forwardCalls',
    path: 'forwarded-calls',
    props: {
      title: 'Forwarded calls',
    },
    component: () => import('@/components/router/RouterViewInheritSlots.vue'),
    redirect: { name: 'base.history.forwardCalls.main' },
    children: [
      {
        name: 'base.history.forwardCalls.main',
        path: 'main',
        props: {
          title: 'Forwarded calls',
        },
        component: () => import('@/components/views/history/forwardCalls/ForwardCalls.vue'),
      },
      {
        name: 'base.history.forwardCalls.details',
        path: 'details',
        component: () => import('@/components/views/history/forwardCalls/ForwardCallsDetails.vue'),
      },
    ],
  },
  {
    name: 'base.history.outboundCalls',
    path: 'outbound-calls',
    props: {
      title: 'Outbound calls',
    },
    component: () => import('@/components/router/RouterViewInheritSlots.vue'),
    redirect: { name: 'base.history.outboundCalls.main' },
    children: [
      {
        name: 'base.history.outboundCalls.main',
        path: 'main',
        props: {
          title: 'Outbound calls',
        },
        component: () => import('@/components/views/history/outboundCalls/OutboundCalls.vue'),
      },
      {
        name: 'base.history.outboundCalls.details',
        path: 'details',
        component: () => import('@/components/views/history/outboundCalls/OutboundCallsDetails.vue'),
      },
    ],
  },
  {
    name: 'base.history.inboundCalls',
    path: 'inbound-calls',
    props: {
      title: 'Inbound calls',
    },
    component: () => import('@/components/router/RouterViewInheritSlots.vue'),
    redirect: { name: 'base.history.inboundCalls.main' },
    children: [
      {
        name: 'base.history.inboundCalls.main',
        path: 'main',
        props: {
          title: 'Inbound calls',
        },
        component: () => import('@/components/views/history/inboundCalls/InboundCalls.vue'),
      },
      {
        name: 'base.history.inboundCalls.details',
        path: ':id',
        component: () => import('@/components/views/history/inboundCalls/InboundCallsDetails.vue'),
      },
    ],
  },
]
