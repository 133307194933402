import type { RouteRecordRaw } from 'vue-router'
import PassThrough from '@/components/layout/PassThrough.vue'

export const servicesRoutes: Array<RouteRecordRaw> = [
  {
    name: 'base.services.senderSettings',
    path: 'sender-settings',
    props: {
      title: 'Sender settings',
    },
    component: () => import('@/components/views/services/senderSettings/ServicesSenderSettings.vue'),
    redirect: {
      name: 'base.services.senderSettings.numbers',
    },
    children: [
      {
        name: 'base.services.senderSettings.countries',
        path: 'countries',
        props: {
          title: 'Countries',
        },
        component: () => import('@/components/views/services/senderSettings/ServicesCountries.vue'),
      },
      {
        name: 'base.services.senderSettings.senderIDs',
        path: 'sender-ids',
        props: {
          title: 'Sender IDs',
        },
        component: () => import('@/components/views/services/senderSettings/ServicesCountriesSender.vue'),
      },
      {
        name: 'base.services.senderSettings.10DLC',
        path: '10dlc',
        component: () => import('@/components/views/services/senderSettings/numbers/ServicesNumbersDlcRegistration.vue'),
      },
      {
        name: 'base.services.senderSettings.tollFreeVerification',
        path: 'toll-free-verification',
        component: () => import('@/components/views/services/senderSettings/numbers/ServicesNumbersTollFree.vue'),
      },
      {
        name: 'base.services.senderSettings.numbers',
        path: 'numbers',
        props: {
          title: 'Numbers',
        },
        component: () => import('@/components/views/services/senderSettings/numbers/ServicesNumbers.vue'),
      },
    ],
  },
  {
    name: 'base.services.byoc',
    path: 'byoc',
    props: {
      title: 'Bring your own CPaaS',
    },
    component: () => import('@/components/views/services/byoc/ServicesByoc.vue'),
    redirect: {
      name: 'base.services.byoc.start',
    },
    children: [
      {
        name: 'base.services.byoc.start',
        path: 'start',
        props: {
          title: 'Bring your own carrier',
        },
        component: () => import('@/components/views/services/byoc/ServicesByocStart.vue'),
      },
      {
        name: 'base.services.byoc.list',
        path: 'list',
        props: {
          title: 'Bring your own carrier',
        },
        component: () => import('@/components/views/services/byoc/ServicesByocList.vue'),
      },
    ],
  },
  {
    name: 'base.services.emailToSms',
    path: 'email-to-sms',
    props: {
      title: 'Email to sms',
    },
    component: () => import('@/components/views/services/emailToSms/ServicesEmailToSms.vue'),
    redirect: {
      name: 'base.services.emailToSms.allowedEmails',
    },
    children: [
      {
        name: 'base.services.emailToSms.allowedEmails',
        path: 'allowed-emails',
        props: {
          title: 'Allowed emails',
        },
        component: () => import('@/components/views/services/emailToSms/allowedEmails/ServicesAllowedEmails.vue'),
      },
      {
        name: 'base.services.emailToSms.sendAndReceiveSettings',
        path: 'send-and-receive-settings',
        props: {
          title: 'Send & receive settings',
        },
        component: () => import('@/components/views/services/emailToSms/sendAndReceiveSettings/ServicesSendAndReceiveSettings.vue'),
      },
      {
        name: 'base.services.emailToSms.distributionLists',
        path: 'distribution-lists',
        props: {
          title: 'Distribution lists',
        },
        component: () => import('@/components/views/services/emailToSms/distributionLists/ServicesDistributionLists.vue'),
      },
    ],
  },
  {
    name: 'base.services.numbers.buy',
    path: 'numbers',
    component: PassThrough,
    redirect: {
      name: 'base.services.numbers.buy.step1',
    },
    children: [
      {
        name: 'base.services.numbers.buy.step1',
        path: 'buy/number',
        component: () => import('@/components/views/services/senderSettings/numbers/buy/ServicesNumbersBuyStep1.vue'),
      },
      {
        name: 'base.services.numbers.buy.step2',
        path: 'buy/call-forwarding',
        component: () => import('@/components/views/services/senderSettings/numbers/buy/ServicesNumbersBuyStep2.vue'),
      },
      {
        name: 'base.services.numbers.buy.step3',
        path: 'buy/finish',
        component: () => import('@/components/views/services/senderSettings/numbers/buy/ServicesNumbersBuyStep3.vue'),
      },
      {
        name: 'base.services.numbers.edit.step2',
        path: 'edit/call-forwarding',
        props: {
          isEdit: true,
        },
        component: () => import('@/components/views/services/senderSettings/numbers/buy/ServicesNumbersBuyStep2.vue'),
      },
    ],
  },
  {
    name: 'base.services.distributionLists.flow',
    path: 'distribution-lists/new',
    component: () => import('@/components/views/services/emailToSms/distributionLists/ServicesDistributionListFlow.vue'),
  },
  {
    name: 'base.services.distributionLists.details',
    path: 'distribution-lists/:id',
    props: {
      listName: 'My New List',
    },
    component: () => import('@/components/views/services/emailToSms/distributionLists/ServicesDistributionListFlow.vue'),
  },
  // Lookup
  {
    name: 'base.services.carrierLookup',
    path: 'carrier-lookup',
    props: {
      title: 'Carrier lookup',
    },
    component: () => import('@/components/views/services/carrierLookup/ServicesCarrierLookup.vue'),
    redirect: { name: 'base.services.carrierLookup.index' },
    children: [
      {
        name: 'base.services.carrierLookup.index',
        path: '',
        component: () => import('@/components/views/services/carrierLookup/ServicesCarrierSingleLookup.vue'),
      },
      {
        name: 'base.services.carrierLookup.bulkLookup',
        path: 'bulk-lookup',
        component: () => import('@/components/views/services/carrierLookup/ServicesCarrierBulkLookup.vue'),
      },
      {
        name: 'base.services.carrierLookup.history',
        path: 'history',
        component: () => import('@/components/views/services/carrierLookup/ServicesCarrierLookupHistory.vue'),
      },
    ],
  },
  {
    name: 'base.services.bulkLookup.newFlow',
    path: 'bulk-carrier-lookup',
    props: {
      title: 'Bulk carrier lookup',
    },
    component: () => import('@/components/views/services/carrierLookup/ServicesCarrierLookupNewFlow.vue'),
  },
  {
    name: 'base.services.bulkLookup.details',
    path: 'bulk-carrier-lookup/contacts-migration-list-2021',
    props: {
      title: 'Contacts migration list 2021.xlsx',
    },
    component: () => import('@/components/views/services/carrierLookup/ServicesCarrierLookupDetails.vue'),
  },
  {
    name: 'base.services.emailLookup',
    path: 'email-lookup',
    props: {
      title: 'Email lookup',
    },
    component: () => import('@/components/views/services/emailLookup/ServicesEmailLookup.vue'),
    redirect: { name: 'base.services.emailLookup.index' },
    children: [
      {
        name: 'base.services.emailLookup.index',
        path: '',
        component: () => import('@/components/views/services/emailLookup/ServicesEmailSingleLookup.vue'),
      },
      {
        name: 'base.services.emailLookup.bulkLookup',
        path: 'bulk-lookup',
        component: () => import('@/components/views/services/emailLookup/ServicesEmailBulkLookup.vue'),
      },
      {
        name: 'base.services.emailLookup.history',
        path: 'history',
        component: () => import('@/components/views/services/emailLookup/ServicesEmailLookupHistory.vue'),
      },
    ],
  },
  // Other services
  {
    name: 'base.services.api',
    path: 'api',
    props: {
      title: 'API',
    },
    component: () => import('@/components/views/services/api/ServicesApi.vue'),
    redirect: { name: 'base.services.api.keys' },
    children: [
      {
        name: 'base.services.api.keys',
        path: '',
        component: () => import('@/components/views/services/api/ServicesApiKeys.vue'),
      },
      {
        name: 'base.services.api.settings',
        path: '2Settings',
        component: () => import('@/components/views/services/api/ServicesApiSettings.vue'),
      },
      {
        name: 'base.services.api.integrations',
        path: 'integrations',
        component: () => import('@/components/views/services/api/ServicesApiIntegrations.vue'),
      },
      {
        name: 'base.services.api.verify',
        path: 'verify',
        component: () => import('@/components/views/services/api/ServicesApiVerify.vue'),
      },
      {
        name: 'base.services.api.legacy',
        path: 'legacy',
        component: () => import('@/components/views/services/api/ServicesApiLegacy.vue'),
      },
    ],
  },
  {
    name: 'base.services.automationRules',
    path: 'automation-rules',
    props: {
      title: 'Automation rules',
    },
    component: () => import('@/components/views/services/automationRules/ServicesAutomationRules.vue'),
    children: [
      {
        name: 'base.services.automationRules.rules',
        path: '',
        component: () => import('@/components/views/services/automationRules/ServicesAutomationRulesIndex.vue'),
      },
      {
        name: 'base.services.automationRules.keywords',
        path: 'keywords',
        component: () => import('@/components/views/services/automationRules/ServicesAutomationRulesKeywords.vue'),
      },
      {
        name: 'base.services.automationRules.logs',
        path: 'logs',
        component: () => import('@/components/views/services/automationRules/ServicesAutomationRulesLogs.vue'),
      },
    ],
  },
  {
    name: 'base.services.automationRules.rules.details',
    path: 'automation-rules/:id',
    component: () => import('@/components/views/services/automationRules/ServicesAutomationRulesDetails.vue'),
  },
  {
    name: 'base.services.automationRules.logs.details',
    path: 'automation-rules/logs/:id',
    component: () => import('@/components/views/services/automationRules/ServicesAutomationRulesLogsDetails.vue'),
  },
  {
    name: 'base.services.automationRules.newRule',
    path: 'automation-rules/new-rule',
    component: () => import('@/components/views/services/automationRules/ServicesAutomationRulesFlow.vue'),
  },
  {
    name: 'base.services.smsSurveys',
    path: 'sms-surveys',
    props: {
      title: 'SMS Surveys',
    },
    component: () => import('@/components/views/services/smsSurveys/ServicesSmsSurveys.vue'),
  },
  {
    name: 'base.services.smsSurveysFlow',
    path: 'sms-surveys/test-survey',
    meta: {
      containerName: 'SurveysContainer',
    },
    component: () => import('@/components/views/services/smsSurveys/ServicesSmsSurveysFlow.vue'),
  },
  {
    name: 'base.services.smsSurveys.details',
    path: 'sms-surveys/:id',
    component: () => import('@/components/views/services/smsSurveys/details/ServicesSmsSurveysDetails.vue'),
    redirect: { name: 'base.services.smsSurveys.details.overview' },
    children: [
      {
        name: 'base.services.smsSurveys.details.overview',
        path: '',
        component: () => import('@/components/views/services/smsSurveys/details/ServicesSmsSurveysDetailsOverview.vue'),
      },
      {
        name: 'base.services.smsSurveys.details.replies',
        path: 'replies/:idQuest?',
        component: () => import('@/components/views/services/smsSurveys/details/ServicesSmsSurveysDetailsReplies.vue'),
      },
      {
        name: 'base.services.smsSurveys.details.analytics',
        path: 'analytics',
        component: () => import('@/components/views/services/smsSurveys/details/ServicesSmsSurveysDetailsAnalytics.vue'),
      },
    ],
  },
  {
    name: 'base.services.textToSpeech',
    path: 'text-to-speech',
    props: {
      title: 'Text to Speech',
    },
    component: () => import('@/components/views/services/textToSpeech/ServicesTextToSpeech.vue'),
  },
  {
    name: 'base.services.forms',
    path: 'forms',
    props: {
      title: 'Forms',
    },
    component: () => import('@/components/views/services/forms/ServicesForms.vue'),
    redirect: { name: 'base.services.forms.index' },
    children: [
      {
        name: 'base.services.forms.index',
        path: '',
        component: () => import('@/components/views/services/forms/ServicesFormsClick.vue'),
      },
      {
        name: 'base.services.forms.subscribe',
        path: 'subscribe',
        component: () => import('@/components/views/services/forms/ServicesFormsSubscribe.vue'),
      },
    ],
  },
  {
    name: 'base.services.forms.new',
    path: 'forms/new',
    props: {
      title: 'New Click-to-text-form',
    },
    component: () => import('@/components/views/services/forms/flows/ServicesFormsClickFlow.vue'),
  },
  {
    name: 'base.services.forms.subscribe.new',
    path: 'forms/subscribe/new',
    props: {
      title: 'New Subscribe form',
    },
    component: () => import('@/components/views/services/forms/flows/ServicesFormsSubscribeFlow.vue'),
  },
  {
    name: 'base.services.forms.edit',
    path: 'forms/edit/:id',
    props: {
      title: 'Edit click-to-text-form',
    },
    component: () => import('@/components/views/services/forms/flows/ServicesFormsClickFlow.vue'),
  },
  {
    name: 'base.services.forms.subscribe.edit',
    path: 'forms/subscribe/edit/:id',
    props: {
      title: 'Edit subscribe form',
    },
    component: () => import('@/components/views/services/forms/flows/ServicesFormsSubscribeFlow.vue'),
  },
  {
    name: 'base.services.downloads',
    path: 'downloads',
    props: {
      title: 'Downloads',
    },
    component: () => import('@/components/views/services/downloads/ServicesDownloads.vue'),
  },
]
