import type { PageContentSidebarItem } from '@/definitions/_layout/pageContent/sidebar/types'

export const adminSidebarRoutes: PageContentSidebarItem[] = [
  {
    itemType: 'title',
    title: 'Workspace',
  },
  {
    title: 'Users',
    route: { name: 'base.admin.users' },
  },
  /*
  {
    title: 'Team members',
    route: { name: 'base.admin.teamMembers' },
  },
  {
    title: 'Sub-accounts',
    route: { name: 'base.admin.subAccounts' },
  },
  {
    title: 'Roles',
    route: { name: 'base.admin.roles' },
  },
  {
    title: 'Settings',
    route: { name: 'base.admin.settings' },
  },
  */
  {
    title: 'Single Sign-On (SSO)',
    route: { name: 'base.admin.sso' },
  },
  {
    title: 'Data retention',
    route: { name: 'base.admin.data' },
  },
  {
    title: 'Workspace security',
    route: { name: 'base.admin.workspaceSecurity' },
  },
  {
    title: 'Audit logs',
    route: { name: 'base.admin.auditLogs' },
  },
  {
    itemType: 'title',
    title: 'Billing',
  },
  {
    title: 'Billing overview',
    route: { name: 'base.admin.overview' },
  },
  {
    title: 'Plans',
    route: { name: 'base.admin.plans' },
  },
  {
    title: 'Invoices',
    route: { name: 'base.admin.invoices' },
  },
  {
    title: 'Statements',
    route: { name: 'base.admin.statements' },
  },
  {
    itemType: 'title',
    title: 'Personal',
  },
  {
    title: 'My account',
    route: { name: 'base.admin.myAccount' },
  },
  {
    title: 'Security',
    route: { name: 'base.admin.security' },
  },
  {
    title: 'Notifications',
    route: { name: 'base.admin.notifications' },
  },
]
