import type { RouteRecordRaw } from 'vue-router'

export const uiRoutes: Array<RouteRecordRaw> = [
  {
    name: 'ui',
    path: '/ui',
    redirect: '/ui/titles',
    component: () => import('@/components/views/ui/Index.vue'),
    children: [
      {
        path: 'alerts',
        name: 'ui.alerts',
        meta: {
          title: 'UI Alerts',
        },
        component: () => import('@/components/views/ui/Alerts.vue'),
      },
      {
        path: 'emoji',
        name: 'ui.emoji',
        meta: {
          title: 'UI Emoji',
        },
        component: () => import('@/components/views/ui/Emoji.vue'),
      },
      {
        path: 'avatars',
        name: 'ui.avatars',
        meta: {
          title: 'UI Avatars',
        },
        component: () => import('@/components/views/ui/Avatars.vue'),
      },
      {
        path: 'breakpoints',
        name: 'ui.breakpoints',
        meta: {
          title: 'UI Breakpoints',
        },
        component: () => import('@/components/views/ui/Breakpoints.vue'),
      },
      {
        path: 'buttons',
        name: 'ui.buttons',
        meta: {
          title: 'UI Buttons',
        },
        component: () => import('@/components/views/ui/Buttons.vue'),
      },
      {
        path: 'chips',
        name: 'ui.chips',
        meta: {
          title: 'UI Chips',
        },
        component: () => import('@/components/views/ui/Chips.vue'),
      },
      {
        path: 'charts',
        name: 'ui.charts',
        meta: {
          title: 'UI Charts',
        },
        component: () => import('@/components/views/ui/Charts.vue'),
      },
      {
        path: 'loader',
        name: 'ui.loader',
        meta: {
          title: 'UI Loader',
        },
        component: () => import('@/components/views/ui/Loader.vue'),
      },
      {
        path: 'countries',
        name: 'ui.countries',
        meta: {
          title: 'UI Countries and Flags',
        },
        component: () => import('@/components/views/ui/Countries.vue'),
      },
      {
        path: 'dropdowns',
        name: 'ui.dropdowns',
        meta: {
          title: 'UI Dropdowns',
        },
        component: () => import('@/components/views/ui/Dropdowns.vue'),
      },
      {
        path: 'fields',
        name: 'ui.fields',
        meta: {
          title: 'UI Fields',
        },
        component: () => import('@/components/views/ui/Fields.vue'),
        redirect: { name: 'ui.fields.input' },
        children: [
          {
            path: 'input',
            name: 'ui.fields.input',
            component: () => import('@/components/views/ui/fields/FieldsInput.vue'),
          },
          {
            path: 'textarea',
            name: 'ui.fields.textarea',
            component: () => import('@/components/views/ui/fields/FieldsTextarea.vue'),
          },
          {
            path: 'select',
            name: 'ui.fields.select',
            component: () => import('@/components/views/ui/fields/FieldsSelect.vue'),
          },
          {
            path: 'checkbox',
            name: 'ui.fields.checkbox',
            component: () => import('@/components/views/ui/fields/FieldsCheckbox.vue'),
          },
          {
            path: 'search',
            name: 'ui.fields.search',
            component: () => import('@/components/views/ui/fields/FieldsSearch.vue'),
          },
          {
            path: 'number',
            name: 'ui.fields.number',
            component: () => import('@/components/views/ui/fields/FieldsNumber.vue'),
          },
          {
            path: 'datepicker',
            name: 'ui.fields.datepicker',
            component: () => import('@/components/views/ui/fields/FieldsDatepicker.vue'),
          },
          {
            path: 'colorpicker',
            name: 'ui.fields.colorpicker',
            component: () => import('@/components/views/ui/fields/FieldsColorpicker.vue'),
          },
          {
            path: 'tags',
            name: 'ui.fields.tags',
            component: () => import('@/components/views/ui/fields/FieldsTags.vue'),
          },
        ],
      },
      {
        path: 'files',
        name: 'ui.files',
        meta: {
          title: 'UI Files',
        },
        component: () => import('@/components/views/ui/Files.vue'),
      },
      {
        path: 'icons',
        name: 'ui.icons',
        meta: {
          title: 'UI Icons',
        },
        component: () => import('@/components/views/ui/Icons.vue'),
      },
      {
        path: 'modals',
        name: 'ui.modals',
        meta: {
          title: 'UI Modals',
        },
        component: () => import('@/components/views/ui/modals/Modals.vue'),
      },
      {
        path: 'tables',
        name: 'ui.tables',
        meta: {
          title: 'UI Tables',
        },
        component: () => import('@/components/views/ui/Tables.vue'),
      },
      {
        path: 'progress',
        name: 'ui.progress',
        meta: {
          title: 'UI Progress & Loaders',
        },
        component: () => import('@/components/views/ui/Progress.vue'),
      },
      {
        path: 'titles',
        name: 'ui.titles',
        meta: {
          title: 'UI Titles',
        },
        component: () => import('@/components/views/ui/Titles.vue'),
      },
      {
        path: 'text',
        name: 'ui.text',
        meta: {
          title: 'UI Text',
        },
        component: () => import('@/components/views/ui/Text.vue'),
      },
      {
        path: 'uploaders',
        name: 'ui.uploaders',
        meta: {
          title: 'UI Uploaders',
        },
        component: () => import('@/components/views/ui/Uploaders.vue'),
      },
      {
        path: 'tooltips',
        name: 'ui.tooltips',
        meta: {
          title: 'UI Tooltips',
        },
        component: () => import('@/components/views/ui/Tooltips.vue'),
      },
      {
        path: 'list',
        name: 'ui.list',
        meta: {
          title: 'UI List',
        },
        component: () => import('@/components/views/ui/List.vue'),
      },
      {
        path: 'expansion',
        name: 'ui.expansion',
        meta: {
          title: 'UI Expansion',
        },
        component: () => import('@/components/views/ui/Expansion.vue'),
      },
      {
        path: 'tabs',
        name: 'ui.tabs',
        meta: {
          title: 'UI Tabs',
        },
        component: () => import('@/components/views/ui/Tabs.vue'),
      },
      {
        path: 'color',
        name: 'ui.color',
        meta: {
          title: 'UI Color',
        },
        component: () => import('@/components/views/ui/Color.vue'),
      },
      {
        path: 'other',
        name: 'ui.other',
        meta: {
          title: 'UI Other',
        },
        component: () => import('@/components/views/ui/Other.vue'),
      },
    ],
  },
]
