import type { RouteRecordRaw } from 'vue-router'
import PassThrough from '@/components/layout/PassThrough.vue'
import PassThroughAllSlots from '@/components/views/PassThroughAllSlots.vue'

export const dealsRoutes: Array<RouteRecordRaw> = [
  {
    name: 'base.deals.index',
    path: '',
    redirect: { name: 'base.deals.kanban' },
    component: PassThroughAllSlots,
    children: [
      {
        name: 'base.deals.kanban',
        path: 'kanban',
        component: () => import('@/components/views/deals/DealsKanban.vue'),
      },
      {
        name: 'base.deals.lists',
        path: 'lists',
        component: () => import('@/components/views/deals/DealsLists.vue'),
      },
    ],
  },
  {
    name: 'base.deals.settings',
    path: 'settings',
    props: {
      menuTitle: 'Deals settings',
    },
    redirect: { name: 'base.deals.settings.index' },
    component: () => import('@/components/views/deals/settings/DealsSettings.vue'),
    children: [
      {
        name: 'base.deals.settings.index',
        path: 'index',
        component: () => import('@/components/views/deals/settings/DealsSettingsIndex.vue'),
      },
      {
        name: 'base.deals.settings.detailed',
        path: 'detailed',
        component: () => import('@/components/views/deals/settings/DealsSettingsDetailed.vue'),
      },
      {
        name: 'base.deals.settings.detailedEmpty',
        path: 'detailed-empty',
        component: PassThrough,
        redirect: { name: 'base.deals.settings.detailed' },
      },
      {
        name: 'base.deals.settings.stages',
        path: 'stages',
        component: () => import('@/components/views/deals/settings/DealsSettingsStages.vue'),
      },
    ],
  },
  {
    name: 'base.deals.emptyRoute',
    path: 'emptyRoute',
    component: PassThrough,
    redirect: { name: 'base.deals.kanban' },
  },
]
