import type { RouteRecordRaw } from 'vue-router'
import PassThrough from '@/components/layout/PassThrough.vue'

export const authRoutes: Array<RouteRecordRaw> = [
  {
    name: 'auth',
    path: '/auth',
    redirect: { name: 'auth.signup' },
    component: PassThrough,
    children: [
      {
        name: 'auth.index',
        path: '',
        component: () => import('@/components/views/auth/AuthIndex.vue'),
        redirect: { name: 'auth.signup' },
        children: [
          {
            name: 'auth.login',
            path: 'login',
            meta: {
              title: 'Login',
            },
            component: () => import('@/components/views/auth/Login.vue'),
          },
          {
            name: 'auth.signup',
            path: 'signup',
            meta: {
              title: 'Signup',
            },
            component: () => import('@/components/views/auth/Signup.vue'),
          },
        ],
      },
      {
        name: 'auth.signup2',
        path: 'signup2',
        meta: {
          title: 'Signup2',
        },
        component: () => import('@/components/views/auth/Signup2.vue'),
      },
      {
        name: 'auth.verification.app',
        path: 'verification/app',
        meta: {
          title: 'Enter verification code app',
        },
        component: () => import('@/components/views/auth/EnterVerificationCodeApp.vue'),
      },
      {
        name: 'auth.verification.phone',
        path: 'verification/phone',
        meta: {
          title: 'Enter verification code phone',
        },
        component: () => import('@/components/views/auth/EnterVerificationCodePhone.vue'),
      },
      {
        name: 'auth.verification.email',
        path: 'verification/email',
        meta: {
          title: 'Enter verification code email',
        },
        component: () => import('@/components/views/auth/EnterVerificationCodeEmail.vue'),
      },
      {
        name: 'auth.verification.backup',
        path: 'verification/backup',
        meta: {
          title: 'Enter backup code',
        },
        component: () => import('@/components/views/auth/EnterBackupCode.vue'),
      },
      {
        name: 'auth.verifyEmail',
        path: 'verify-email',
        meta: {
          title: 'Verify email',
        },
        component: () => import('@/components/views/auth/VerifyEmail.vue'),
      },
      {
        name: 'auth.securityQuestion',
        path: 'security-question',
        meta: {
          title: 'Security question',
        },
        component: () => import('@/components/views/auth/SecurityQuestion.vue'),
      },
      {
        name: 'auth.smsCode',
        path: 'sms-code',
        meta: {
          title: 'Sms code',
        },
        component: () => import('@/components/views/auth/SmsCode.vue'),
      },
      {
        name: 'auth.emailCode',
        path: 'email-code',
        meta: {
          title: 'Email code',
        },
        component: () => import('@/components/views/auth/EmailCode.vue'),
      },
      {
        name: 'auth.customSso',
        path: 'custom-sso',
        meta: {
          title: 'Log in with custom SSO',
        },
        component: () => import('@/components/views/auth/CustomSso.vue'),
      },
      {
        name: 'auth.passwordReset',
        path: 'password-reset',
        meta: {
          title: 'Password reset',
        },
        component: () => import('@/components/views/auth/PasswordReset.vue'),
      },
      {
        name: 'auth.forgotPassword',
        path: 'forgot-password',
        meta: {
          title: 'Forgot you account password?',
        },
        component: () => import('@/components/views/auth/ForgotPassword.vue'),
      },
      {
        name: 'auth.resetPassword',
        path: 'reset-password',
        meta: {
          title: 'Reset Password',
        },
        component: () => import('@/components/views/auth/ResetPassword.vue'),
      },
      {
        name: 'auth.resetPasswordSuccess',
        path: 'reset-password-success',
        meta: {
          title: 'Reset Password success',
        },
        component: () => import('@/components/views/auth/ResetPasswordSuccess.vue'),
      },
      {
        name: 'auth.unavailable',
        path: 'unavailable',
        meta: {
          title: 'Sorry, Textmagic is not available in your location',
        },
        component: () => import('@/components/views/auth/Unavailable.vue'),
      },
      {
        name: 'auth.suspended',
        path: 'suspended',
        meta: {
          title: 'Account is suspended',
        },
        component: () => import('@/components/views/auth/Suspended.vue'),
      },
      {
        name: 'auth.deleted',
        path: 'deleted',
        meta: {
          title: 'You have closed your account',
        },
        component: () => import('@/components/views/auth/AccountDeleted.vue'),
      },
      {
        name: 'auth.personalUse',
        path: 'personal-use',
        meta: {
          title: 'Textmagic is available only for business users',
        },
        component: () => import('@/components/views/auth/PersonalUse.vue'),
      },
      {
        name: 'auth.activate',
        path: 'activate',
        meta: {
          title: 'Activate account',
        },
        component: () => import('@/components/views/auth/Activate.vue'),
      },
      {
        name: 'auth.workspaces',
        path: 'workspaces',
        meta: {
          title: 'Choose workspace',
        },
        component: () => import('@/components/views/auth/Workspaces.vue'),
      },
      {
        name: 'auth.workspaces.new',
        path: 'workspaces/new',
        meta: {
          title: 'Create a new workspace',
        },
        component: () => import('@/components/views/auth/WorkspacesNew.vue'),
      },
      {
        name: 'auth.inviteTeam',
        path: 'invite-team',
        meta: {
          title: 'Invite your team',
        },
        component: () => import('@/components/views/auth/InviteTeam.vue'),
      },
      {
        name: 'auth.signupConfirmation',
        path: 'signup-confirmation',
        meta: {
          title: 'Signup confirmation',
        },
        component: () => import('@/components/views/auth/EmailConfirmation.vue'),
      },
      {
        name: 'auth.signup.credentials',
        path: 'signup/credentials',
        meta: {
          title: 'Signup credentials',
        },
        component: () => import('@/components/views/auth/SignupCredentials.vue'),
      },
      {
        name: 'auth.signup.credentialsByEmail',
        path: 'signup/credentials-by-email',
        meta: {
          title: 'Signup credentials by email',
        },
        component: () => import('@/components/views/auth/SignupCredentialsByEmail.vue'),
      },
      {
        name: 'auth.invite',
        path: 'invite',
        meta: {
          title: 'Join workspace invite',
        },
        component: () => import('@/components/views/auth/SignupInvite.vue'),
      },
      {
        name: 'auth.inviteByEmail',
        path: 'invite-by-email',
        meta: {
          title: 'Join workspace invite by email',
        },
        component: () => import('@/components/views/auth/SignupInviteByEmail.vue'),
      },
      {
        name: 'auth.2fa',
        path: '2fa',
        meta: {
          isAuthPage: true,
          title: '2fa',
        },
        component: PassThrough,
        redirect: () => ({ name: 'auth.2fa.organization-required' }),
        children: [
          {
            name: 'auth.2fa.organization-required',
            path: 'organization-required',
            meta: {
              title: 'Organization Required',
            },
            component: () => import('@/components/views/auth/2fa/OrganizationRequired.vue'),
          },
          {
            name: 'auth.2fa.organization-invited',
            path: 'organization-invited',
            meta: {
              title: 'Organization Invited',
            },
            component: () => import('@/components/views/auth/2fa/OrganizationInvited.vue'),
          },
          {
            name: 'auth.2fa.methods',
            path: 'methods',
            meta: {
              title: '2FA Methods',
            },
            component: () => import('@/components/views/auth/2fa/AuthMethod.vue'),
          },
          {
            name: 'auth.2fa.methods-app',
            path: 'methods-app',
            meta: {
              title: '2FA Methods App QR',
            },
            component: () => import('@/components/views/auth/2fa/AppScanQr.vue'),
          },
          {
            name: 'auth.2fa.methods-app-code',
            path: 'methods-app-code',
            meta: {
              title: 'Enter App Code',
            },
            component: () => import('@/components/views/auth/2fa/EnterAppCode.vue'),
          },
          {
            name: 'auth.2fa.methods-phone',
            path: 'methods-phone',
            meta: {
              title: '2FA Methods Phone',
            },
            component: () => import('@/components/views/auth/2fa/AddPhoneNumber.vue'),
          },
          {
            name: 'auth.2fa.methods-phone-code',
            path: 'methods-phone-code',
            meta: {
              title: 'Enter App Code',
            },
            component: () => import('@/components/views/auth/2fa/EnterPhoneCode.vue'),
          },
          {
            name: 'auth.2fa.backup-codes',
            path: 'backup-codes',
            meta: {
              title: 'Backup Codes',
            },
            component: () => import('@/components/views/auth/2fa/BackupCodes.vue'),
          },
        ],
      },
    ],
  },
  {
    name: 'wizard',
    path: '/wizard',
    redirect: { name: 'wizard.au' },
    component: PassThrough,
    children: [
      {
        name: 'wizard.au',
        path: 'au',
        redirect: { name: 'wizard.au.useCase' },
        component: () => import('@/components/containers/WizardContainer.vue'),
        meta: {
          price: 'A$0.40',
          country: 'au',
        },
        children: [
          {
            name: 'wizard.au.useCase',
            path: 'use-case',
            meta: {
              title: 'How are you planning to use Textmagic?',
            },
            component: () => import('@/components/views/auth/wizard/WizardStepOne.vue'),
          },
          {
            name: 'wizard.au.smsSender',
            path: 'sms-sender',
            meta: {
              title: 'Choose the sender of your text messages',
            },
            component: () => import('@/components/views/auth/wizard/au/WizardStepTwoSmsSender.vue'),
          },
          {
            name: 'wizard.au.senderSettings',
            path: 'sender-settings',
            meta: {
              title: 'Choose the number and register your use case',
            },
            component: () => import('@/components/views/auth/wizard/au/WizardStepTwoNumber.vue'),
          },
          {
            name: 'wizard.au.senderId',
            path: 'sender-id',
            meta: {
              title: 'Register the Sender ID and your use case',
            },
            component: () => import('@/components/views/auth/wizard/au/WizardStepTwoSenderId.vue'),
          },
        ],
      },
      {
        name: 'wizard.uk',
        path: 'uk',
        redirect: { name: 'wizard.uk.useCase' },
        component: () => import('@/components/containers/WizardContainer.vue'),
        meta: {
          price: '£0.40',
          country: 'uk',
        },
        children: [
          {
            name: 'wizard.uk.useCase',
            path: 'use-case',
            meta: {
              title: 'How are you planning to use Textmagic?',
            },
            component: () => import('@/components/views/auth/wizard/WizardStepOne.vue'),
          },
          {
            name: 'wizard.uk.smsSender',
            path: 'sms-sender',
            meta: {
              title: 'Choose the sender of your text messages',
            },
            component: () => import('@/components/views/auth/wizard/uk/WizardStepTwoSmsSender.vue'),
          },
          {
            name: 'wizard.uk.senderSettings',
            path: 'sender-settings',
            meta: {
              title: 'Choose the number and register your use case',
            },
            component: () => import('@/components/views/auth/wizard/uk/WizardStepTwoNumber.vue'),
          },
          {
            name: 'wizard.uk.senderId',
            path: 'sender-id',
            meta: {
              title: 'Register the Sender ID and your use case',
            },
            component: () => import('@/components/views/auth/wizard/uk/WizardStepTwoSenderId.vue'),
          },
        ],
      },
      {
        name: 'wizard.us',
        path: 'us',
        redirect: { name: 'wizard.us.useCase' },
        component: () => import('@/components/containers/WizardContainer.vue'),
        meta: {
          price: '$0.50',
          country: 'us',
        },
        children: [
          {
            name: 'wizard.us.useCase',
            path: 'use-case',
            meta: {
              title: 'How are you planning to use Textmagic?',
            },
            component: () => import('@/components/views/auth/wizard/WizardStepOne.vue'),
          },
          {
            name: 'wizard.us.smsSender',
            path: 'sms-sender',
            meta: {
              title: 'Choose the sender of your text messages',
            },
            component: () => import('@/components/views/auth/wizard/us/WizardStepTwoSmsSender.vue'),
          },
          {
            name: 'wizard.us.senderSettings',
            path: 'sender-settings',
            meta: {
              title: 'Choose the number and register your use case',
            },
            component: () => import('@/components/views/auth/wizard/us/WizardStepTwoNumber.vue'),
          },
        ],
      },
      {
        name: 'wizard.ca',
        path: 'ca',
        redirect: { name: 'wizard.ca.useCase' },
        component: () => import('@/components/containers/WizardContainer.vue'),
        meta: {
          price: '$0.50',
          country: 'ca',
        },
        children: [
          {
            name: 'wizard.ca.useCase',
            path: 'use-case',
            meta: {
              title: 'How are you planning to use Textmagic?',
            },
            component: () => import('@/components/views/auth/wizard/WizardStepOne.vue'),
          },
          {
            name: 'wizard.ca.smsSender',
            path: 'sms-sender',
            meta: {
              title: 'Choose the sender of your text messages',
            },
            component: () => import('@/components/views/auth/wizard/ca/WizardStepTwoSmsSender.vue'),
          },
          {
            name: 'wizard.ca.senderSettings',
            path: 'sender-settings',
            meta: {
              title: 'Choose the number and register your use case',
            },
            component: () => import('@/components/views/auth/wizard/ca/WizardStepTwoNumber.vue'),
          },
        ],
      },
      {
        name: 'wizard.private',
        path: 'private',
        meta: {
          title: 'Private',
        },
        component: () => import('@/components/views/auth/wizard/WizardPrivate.vue'),
      },
    ],
  },
]
