import type { RouteRecordRaw } from 'vue-router'
import type { CalendarViewType } from '@/definitions/shared/calendar/types'

export const campaignsRoutes: Array<RouteRecordRaw> = [
  {
    name: 'base.campaigns.sms',
    path: 'sms',
    component: () => import('@/components/views/campaigns/CampaignsCategory.vue'),
    redirect: { name: 'base.campaigns.sms.sent' },
    children: [
      {
        name: 'base.campaigns.sms.sent',
        path: 'sent',
        props: {
          title: 'Sent',
        },
        component: () => import('@/components/views/campaigns/sms/CampaignsSmsSent.vue'),
      },
      {
        name: 'base.campaigns.sms.details',
        path: 'details',
        redirect: { name: 'base.campaigns.sms.details.overview' },
        component: () => import('@/components/views/campaigns/sms/details/CampaignsSmsDetails.vue'),
        children: [
          {
            name: 'base.campaigns.sms.details.overview',
            path: 'overview',
            component: () => import('@/components/views/campaigns/sms/details/CampaignsSmsDetailsOverview.vue'),
          },
          {
            name: 'base.campaigns.sms.details.sent',
            path: 'sent',
            component: () => import('@/components/views/campaigns/sms/details/CampaignsSmsDetailsSent.vue'),
          },
          {
            name: 'base.campaigns.sms.details.responses',
            path: 'responses',
            component: () => import('@/components/views/campaigns/sms/details/CampaignsSmsDetailsResponses.vue'),
          },
        ],
      },
      {
        name: 'base.campaigns.sms.details.content',
        path: 'details/content',
        component: () => import('@/components/views/campaigns/sms/details/CampaignsSmsDetailsContent.vue'),
      },
      {
        name: 'base.campaigns.sms.drafts',
        path: 'drafts',
        props: {
          title: 'Drafts',
        },
        component: () => import('@/components/views/campaigns/CampaignsDraft.vue'),
      },
      {
        name: 'base.campaigns.sms.scheduled',
        path: 'scheduled',
        props: {
          title: 'Scheduled',
        },
        component: () => import('@/components/views/campaigns/CampaignsScheduled.vue'),
      },
      {
        name: 'base.campaigns.sms.calendar',
        path: 'calendar',
        props: {
          title: 'Calendar',
        },
        redirect: { name: 'base.campaigns.sms.calendar.month' },
        component: () => import('@/components/views/campaigns/calendar/CampaignsCalendar.vue'),
        children: [
          {
            name: 'base.campaigns.sms.calendar.month',
            path: 'month',
            meta: {
              calendarView: 'dayGridMonth' as CalendarViewType,
            },
            component: () => import('@/components/views/campaigns/calendar/CampaignsCalendarMonth.vue'),
          },
        ],
      },
      {
        name: 'base.campaigns.sms.templates',
        path: 'templates',
        props: {
          title: 'Templates',
          mockName: 'templatesAllCategories',
        },
        component: () => import('@/components/views/campaigns/CampaignsTemplates.vue'),
      },
      {
        name: 'base.campaigns.sms.flow',
        path: 'flow',
        component: () => import('@/components/views/campaigns/sms/CampaignsSmsFlow.vue'),
      },
    ],
  },
  {
    name: 'base.campaigns.email',
    path: 'email',
    component: () => import('@/components/views/campaigns/CampaignsCategory.vue'),
    redirect: { name: 'base.campaigns.email.sent' },
    children: [
      {
        name: 'base.campaigns.email.sent',
        path: 'sent',
        props: {
          title: 'Sent',
        },
        component: () => import('@/components/views/campaigns/email/CampaignsEmailSent.vue'),
      },
      {
        name: 'base.campaigns.email.details',
        path: 'details',
        redirect: { name: 'base.campaigns.email.details.overview' },
        component: () => import('@/components/views/campaigns/email/details/CampaignsEmailDetails.vue'),
        children: [
          {
            name: 'base.campaigns.email.details.overview',
            path: 'overview',
            component: () => import('@/components/views/campaigns/email/details/CampaignsEmailDetailsOverview.vue'),
          },
          {
            name: 'base.campaigns.email.details.sent',
            path: 'sent',
            component: () => import('@/components/views/campaigns/email/details/CampaignsEmailDetailsSent.vue'),
          },
          {
            name: 'base.campaigns.email.details.responses',
            path: 'responses',
            component: () => import('@/components/views/campaigns/email/details/CampaignsEmailDetailsResponses.vue'),
          },
          {
            name: 'base.campaigns.email.details.links',
            path: 'links',
            component: () => import('@/components/views/campaigns/email/details/CampaignsEmailDetailsLinks.vue'),
          },
        ],
      },
      {
        name: 'base.campaigns.email.details.content',
        path: 'details/content',
        component: () => import('@/components/views/campaigns/email/details/CampaignsEmailDetailsContent.vue'),
      },
      {
        name: 'base.campaigns.email.drafts',
        path: 'drafts',
        props: {
          title: 'Drafts',
        },
        component: () => import('@/components/views/campaigns/CampaignsDraft.vue'),
      },
      {
        name: 'base.campaigns.email.scheduled',
        path: 'scheduled',
        props: {
          title: 'Scheduled',
        },
        component: () => import('@/components/views/campaigns/CampaignsScheduled.vue'),
      },
      {
        name: 'base.campaigns.email.calendar',
        path: 'calendar',
        props: {
          title: 'Calendar',
        },
        redirect: { name: 'base.campaigns.email.calendar.month' },
        component: () => import('@/components/views/campaigns/calendar/CampaignsCalendar.vue'),
        children: [
          {
            name: 'base.campaigns.email.calendar.month',
            path: 'month',
            meta: {
              calendarView: 'dayGridMonth' as CalendarViewType,
            },
            component: () => import('@/components/views/campaigns/calendar/CampaignsCalendarMonth.vue'),
          },
        ],
      },
      {
        name: 'base.campaigns.email.templates',
        path: 'templates',
        props: {
          title: 'Templates',
          mockName: 'templatesAllCategories',
        },
        component: () => import('@/components/views/campaigns/CampaignsTemplates.vue'),
      },
      {
        name: 'base.campaigns.email.flow',
        path: 'flow',
        component: () => import('@/components/views/campaigns/email/CampaignsEmailFlow.vue'),
      },
    ],
  },
]
