import type { RouteRecordRaw } from 'vue-router'

export const contactsRoutes: Array<RouteRecordRaw> = [
  {
    name: 'base.contacts.allContacts',
    path: 'all-contacts',
    props: {
      title: 'All contacts',
    },
    component: () => import('@/components/views/contacts/allContacts/ContactsAllContacts.vue'),
  },
  {
    name: 'base.contacts.contactDetails',
    path: ':id',
    meta: {
      hideInMenu: true,
      title: 'Contact details',
      tab: 'contact',
    },
    component: () => import('@/components/views/contacts/contactDetails/ContactDetails.vue'),
  },
  {
    name: 'base.contacts.lists',
    path: 'lists',
    props: {
      title: 'Lists',
    },
    component: () => import('@/components/views/contacts/lists/ContactsLists.vue'),
    redirect: { name: 'base.contacts.lists.main' },
    children: [
      {
        name: 'base.contacts.lists.main',
        path: '',
        meta: {
          title: 'Lists main',
        },
        component: () => import('@/components/views/contacts/lists/ContactsListsMain.vue'),
      },
      {
        name: 'base.contacts.lists.details',
        path: ':id',
        meta: {
          title: 'Lists details',
          tab: 'contact_list',
        },
        component: () => import('@/components/views/contacts/lists/ContactsListsDetails.vue'),
      },
    ],
  },
  {
    name: 'base.contacts.segments',
    path: 'segments',
    props: {
      title: 'Segments',
    },
    component: () => import('@/components/views/contacts/segments/ContactsSegments.vue'),
    redirect: { name: 'base.contacts.segments.main' },
    children: [
      {
        name: 'base.contacts.segments.main',
        path: '',
        meta: {
          title: 'Segments main',
        },
        component: () => import('@/components/views/contacts/segments/ContactsSegmentsMain.vue'),
      },
      {
        name: 'base.contacts.segments.details',
        path: ':id',
        meta: {
          title: 'Segments details',
          tab: 'contact_segment',
        },
        component: () => import('@/components/views/contacts/segments/ContactsSegmentsDetails.vue'),
      },
    ],
  },
  {
    name: 'base.contacts.organizations',
    path: 'organizations',
    props: {
      title: 'Organizations',
    },
    component: () => import('@/components/views/contacts/organizations/ContactsOrganizations.vue'),
  },
  {
    name: 'base.contacts.organizationDetails',
    path: 'organizations/:id',
    meta: {
      hideInMenu: true,
      title: 'Organization details',
      tab: 'contact_organization',
    },
    redirect: { name: 'base.contacts.organizationDetails.contacts' },
    component: () => import('@/components/views/contacts/organizationDetails/OrganizationDetails.vue'),
    children: [
      {
        name: 'base.contacts.organizationDetails.contacts',
        path: 'contacts',
        component: () => import('@/components/views/contacts/organizationDetails/OrganizationDetailsContacts.vue'),
        meta: {
          title: 'Contacts',
        },
      },
      {
        name: 'base.contacts.organizationDetails.activities',
        path: 'activities',
        component: () => import('@/components/views/contacts/organizationDetails/OrganizationDetailsActivities.vue'),
        meta: {
          title: 'Activities',
        },
      },
      {
        name: 'base.contacts.organizationDetails.notes',
        path: 'notes',
        component: () => import('@/components/views/contacts/organizationDetails/OrganizationDetailsNotes.vue'),
        meta: {
          title: 'Notes',
        },
      },
      {
        name: 'base.contacts.organizationDetails.chats',
        path: 'chats',
        component: () => import('@/components/views/contacts/organizationDetails/OrganizationDetailsChats.vue'),
        meta: {
          title: 'Chats',
        },
      },
      {
        name: 'base.contacts.organizationDetails.calls',
        path: 'calls',
        component: () => import('@/components/views/contacts/organizationDetails/OrganizationDetailsCalls.vue'),
        meta: {
          title: 'Calls',
        },
      },
      {
        name: 'base.contacts.organizationDetails.emails',
        path: 'emails',
        component: () => import('@/components/views/contacts/organizationDetails/OrganizationDetailsEmails.vue'),
        meta: {
          title: 'Emails',
        },
      },
      {
        name: 'base.contacts.organizationDetails.files',
        path: 'files',
        component: () => import('@/components/views/contacts/organizationDetails/OrganizationDetailsFiles.vue'),
        meta: {
          title: 'Files',
        },
      },
    ],
  },
  {
    name: 'base.contacts.import',
    path: 'import',
    props: {
      title: 'Imports',
    },
    component: () => import('@/components/views/contacts/import/ContactsImport.vue'),
  },
  {
    name: 'base.contacts.import.details',
    path: 'import/details/',
    component: () => import('@/components/views/contacts/import/ContactsImportDetails.vue'),
  },
  {
    name: 'base.contacts.unsubscribed',
    path: 'unsubscribed',
    props: {
      title: 'Unsubscribed',
    },
    component: () => import('@/components/views/contacts/ContactsUnsubscribed.vue'),
  },
  {
    name: 'base.contacts.importFlow',
    path: 'import-contacts',
    props: {
      hideInMenu: true,
    },
    component: () => import('@/components/views/contacts/import/flow/ContactsImportFlow.vue'),
  },
  {
    name: 'base.contacts.settings',
    path: 'settings',
    props: {
      title: 'Settings',
    },
    component: () => import('@/components/views/contacts/settings/ContactsSettings.vue'),
  },
  {
    name: 'base.contacts.settings.contact.dataFields',
    path: 'contact/dataFields',
    component: () => import('@/components/views/contacts/settings/contact/DataFields.vue'),
  },
  {
    name: 'base.contacts.settings.contact.types',
    path: 'contact/types',
    component: () => import('@/components/views/contacts/settings/contact/Types.vue'),
  },
  {
    name: 'base.contacts.settings.contact.optOut',
    path: 'contact/optOut',
    component: () => import('@/components/views/contacts/settings/contact/OptOut.vue'),
  },
  {
    name: 'base.contacts.settings.organization.dataFields',
    path: 'organization/dataFields',
    component: () => import('@/components/views/contacts/settings/organization/DataFields.vue'),
  },
  {
    name: 'base.contacts.settings.organization.types',
    path: 'organization/types',
    component: () => import('@/components/views/contacts/settings/organization/Types.vue'),
  },
]
