import type { PageContentSidebarItem } from '@/definitions/_layout/pageContent/sidebar/types'

export const reportingSidebarRoutes: PageContentSidebarItem[] = [
  {
    title: 'Overview',
    route: { name: 'base.reporting.overview' },
  },
  {
    title: 'Text messages',
    route: { name: 'base.reporting.messages' },
  },
  {
    title: 'Calls',
    route: { name: 'base.reporting.calls' },
  },
  {
    title: 'Users',
    route: { name: 'base.reporting.subAccounts' },
  },
  {
    title: 'Numbers',
    route: { name: 'base.reporting.numbers' },
  },
]

export const reportingComingSoonSidebarRoutes: PageContentSidebarItem[] = [
  {
    itemType: 'title',
    title: 'Coming soon',
  },
  {
    title: 'Helpdesk',
    route: { name: 'base.reporting.helpdesk' },
  },
  {
    title: 'Tasks',
    route: { name: 'base.reporting.tasks' },
  },
  {
    title: 'Deals',
    route: { name: 'base.reporting.deals' },
  },
]
