import type { RouteRecordRaw } from 'vue-router'
import PassThrough from '@/components/layout/PassThrough.vue'

export const chatsRoutes: Array<RouteRecordRaw> = [
  {
    name: 'base.chats.main',
    path: '',
    meta: {
      // showSidebarOnHover: true,
      // sidebarCollapsed: true,
      title: 'Chats',
    },
    component: () => import('@/components/views/chats/Chat.vue'),
    redirect: { name: 'base.chats.open' },
    children: [
      {
        name: 'base.chats.open',
        path: 'open',
        meta: {
          title: 'Open',
        },
        component: () => import('@/components/views/chats/Chat.vue'),
      },
      {
        name: 'base.chats.waiting-now',
        path: 'waiting-now',
        meta: {
          title: 'Waiting now',
        },
        component: () => import('@/components/views/chats/Chat.vue'),
      },
      {
        name: 'base.chats.unread',
        path: 'unread',
        meta: {
          title: 'Unread',
        },
        component: () => import('@/components/views/chats/Chat.vue'),
      },
      {
        name: 'base.chats.unassigned',
        path: 'unassigned',
        meta: {
          title: 'Unassigned',
        },
        component: () => import('@/components/views/chats/Chat.vue'),
      },
      {
        name: 'base.chats.assigned-to-me',
        path: 'assigned-to-me',
        meta: {
          title: 'Assigned to me',
        },
        component: () => import('@/components/views/chats/Chat.vue'),
      },
      {
        name: 'base.chats.solved',
        path: 'solved',
        meta: {
          title: 'Solved',
        },
        component: () => import('@/components/views/chats/Chat.vue'),
      },
      {
        name: 'base.chats.all-chats',
        path: 'all-chats',
        meta: {
          title: 'All chats',
        },
        component: () => import('@/components/views/chats/Chat.vue'),
      },
    ],
  },
  {
    name: 'base.chats.settings',
    path: 'settings',
    meta: {
      title: 'Chats settings',
    },
    component: () => import('@/components/views/chats/ChatSettings.vue'),
  },
  {
    name: 'base.chats.settings.channels',
    path: 'channels',
    meta: {
      title: 'Chats channels',
    },
    component: PassThrough,
    redirect: { name: 'base.chats.settings.channels.index' },
    children: [
      {
        name: 'base.chats.settings.channels.index',
        path: '',
        meta: {
          title: 'Chats channels',
        },
        component: () => import('@/components/views/chats/channels/ChatChannels.vue'),
      },
      {
        name: 'base.chats.settings.channels.flow',
        path: 'flow',
        props: {
          title: 'Connect virtual numbers via CPaaS',
        },
        component: () => import('@/components/views/chats/channels/ChatByocFlow.vue'),
      },
    ],
  },
  {
    name: 'base.chats.settings.numberDetails',
    path: 'number-details',
    meta: {
      title: 'Number details',
    },
    component: () => import('@/components/views/chats/ChatNumberDetails.vue'),
  },
  {
    name: 'base.chats.settings.whatsApp',
    path: 'whats-app',
    meta: {
      title: 'WhatsApp',
    },
    component: PassThrough,
    redirect: { name: 'base.chats.settings.whatsApp.index' },
    children: [
      {
        name: 'base.chats.settings.whatsApp.index',
        path: '',
        meta: {
          title: 'WhatsApp',
        },
        component: () => import('@/components/views/chats/whatsApp/ChatWhatsApp.vue'),
      },
      {
        name: 'base.chats.settings.whatsApp.flow',
        path: 'flow',
        props: {
          title: 'Connect a new WhatsApp sender',
        },
        component: () => import('@/components/views/chats/whatsApp/ChatWhatsAppFlow.vue'),
      },
      {
        name: 'base.chats.settings.whatsApp.detailed',
        path: 'detailed',
        component: () => import('@/components/views/chats/whatsApp/ChatWhatsAppDetailed.vue'),
      },
      {
        name: 'base.chats.settings.whatsApp.number',
        path: 'number',
        component: () => import('@/components/views/chats/whatsApp/ChatWhatsAppNumber.vue'),
      },
    ],
  },
  {
    name: 'base.chats.settings.facebookMessenger',
    path: 'facebook-messenger',
    meta: {
      title: 'Facebook Messenger',
    },
    component: PassThrough,
    redirect: { name: 'base.chats.settings.facebookMessenger.index' },
    children: [
      {
        name: 'base.chats.settings.facebookMessenger.index',
        path: '',
        meta: {
          title: 'Facebook Messenger',
        },
        component: () => import('@/components/views/chats/facebookMessenger/ChatFacebookMessenger.vue'),
      },
      {
        name: 'base.chats.settings.facebookMessenger.flow',
        path: 'flow',
        props: {
          title: 'Connect Facebook page',
        },
        component: () => import('@/components/views/chats/facebookMessenger/ChatFacebookMessengerFlow.vue'),
      },
      {
        name: 'base.chats.settings.facebookMessenger.detailed',
        path: 'detailed',
        component: () => import('@/components/views/chats/facebookMessenger/ChatFacebookMessengerDetailed.vue'),
      },
    ],
  },
  {
    name: 'base.chats.settings.instagram',
    path: 'instagram',
    meta: {
      title: 'Instagram',
    },
    component: PassThrough,
    redirect: { name: 'base.chats.settings.instagram.index' },
    children: [
      {
        name: 'base.chats.settings.instagram.index',
        path: '',
        meta: {
          title: 'Instagram',
        },
        component: () => import('@/components/views/chats/instagram/ChatInstagram.vue'),
      },
      {
        name: 'base.chats.settings.instagram.flow',
        path: 'flow',
        props: {
          title: 'Connect Instagram account',
        },
        component: () => import('@/components/views/chats/instagram/ChatInstagramFlow.vue'),
      },
      {
        name: 'base.chats.settings.instagram.detailed',
        path: 'detailed',
        component: () => import('@/components/views/chats/instagram/ChatInstagramDetailed.vue'),
      },
    ],
  },
  {
    name: 'base.chats.settings.byoc',
    path: 'byoc',
    meta: {
      title: 'Bring your own CPaaS',
    },
    component: PassThrough,
    redirect: { name: 'base.chats.settings.byoc.index' },
    children: [
      {
        name: 'base.chats.settings.byoc.index',
        path: '',
        meta: {
          title: 'Bring your own CPaaS',
        },
        component: () => import('@/components/views/chats/byoc/Byoc.vue'),
        redirect: { name: 'base.chats.settings.byoc.providers' },
        children: [
          {
            name: 'base.chats.settings.byoc.providers',
            path: 'providers',
            meta: {
              title: 'Providers',
            },
            component: () => import('@/components/views/chats/byoc/ByocProviders.vue'),
          },
          {
            name: 'base.chats.settings.byoc.numbers',
            path: 'numbers',
            meta: {
              title: 'Numbers',
            },
            component: () => import('@/components/views/chats/byoc/ByocNumbers.vue'),
          },
          {
            name: 'base.chats.settings.byoc.senderIDs',
            path: 'sender-ids',
            meta: {
              title: 'Sender IDs',
            },
            component: () => import('@/components/views/chats/byoc/ByocSenderIDs.vue'),
          },
        ],
      },
      {
        name: 'base.chats.settings.byoc.details',
        path: '',
        meta: {
          title: 'Bring your own CPaaS details',
        },
        component: PassThrough,
        redirect: { name: 'base.chats.settings.byoc.details.sinch' },
        children: [
          {
            name: 'base.chats.settings.byoc.details.sinch',
            path: 'sinch',
            component: () => import('@/components/views/chats/byoc/byocDetails/ByocDetailsSinch.vue'),
          },
          {
            name: 'base.chats.settings.byoc.details.twilio',
            path: 'twilio',
            component: () => import('@/components/views/chats/byoc/byocDetails/ByocDetailsTwilio.vue'),
          },
          {
            name: 'base.chats.settings.byoc.details.bandwidth',
            path: 'bandwidth',
            component: () => import('@/components/views/chats/byoc/byocDetails/ByocDetailsBandwidth.vue'),
          },
          {
            name: 'base.chats.settings.byoc.details.vonage',
            path: 'vonage',
            component: () => import('@/components/views/chats/byoc/byocDetails/ByocDetailsVonage.vue'),
          },
          {
            name: 'base.chats.settings.byoc.details.infobip',
            path: 'infobip',
            component: () => import('@/components/views/chats/byoc/byocDetails/ByocDetailsInfobip.vue'),
          },
        ],
      },
    ],
  },
  {
    name: 'base.chats.settings.webWidgets',
    path: 'web-widgets',
    meta: {
      title: 'Web widgets',
    },
    component: PassThrough,
    redirect: { name: 'base.chats.settings.webWidgets.index' },
    children: [
      {
        name: 'base.chats.settings.webWidgets.index',
        path: 'index',
        component: () => import('@/components/views/chats/channels/ChatWebWidgets.vue'),
      },
      {
        name: 'base.chats.settings.webWidgets.create',
        path: 'create',
        component: () => import('@/components/views/chats/webWidget/WidgetCreate.vue'),
      },
    ],
  },
  {
    name: 'base.chats.settings.webWidgets.edit',
    path: 'web-widgets-edit',
    meta: {
      title: 'Web widgets details edit',
    },
    component: PassThrough,
    redirect: { name: 'base.chats.settings.webWidgets.edit.create' },
    children: [
      {
        name: 'base.chats.settings.webWidgets.main.index',
        path: 'index',
        component: () => import('@/components/views/chats/ChatWebWidgets.vue'),
      },
      {
        name: 'base.chats.settings.webWidgets.edit.create',
        path: 'create',
        component: () => import('@/components/views/chats/webWidget/WidgetCreate.vue'),
      },
    ],
  },
  {
    name: 'base.chats.settings.autoResponders',
    path: 'auto-responders',
    meta: {
      title: 'Chats auto-responders',
    },
    component: PassThrough,
    redirect: { name: 'base.chats.settings.autoResponders.index' },
    children: [
      {
        name: 'base.chats.settings.autoResponders.index',
        path: '',
        meta: {
          title: 'Chats auto-responders',
        },
        component: () => import('@/components/views/chats/autoResponders/ChatAutoResponders.vue'),
      },
      {
        name: 'base.chats.settings.autoResponders.email',
        path: 'email',
        meta: {
          title: 'Chats auto-responders email',
        },
        component: () => import('@/components/views/chats/autoResponders/ChatAutoRespondersEmail.vue'),
      },
      {
        name: 'base.chats.settings.autoResponders.sms',
        path: 'sms',
        meta: {
          title: 'Chats auto-responders sms',
        },
        props: {
          title: 'SMS auto-responders',
          icon: 'chat',
        },
        component: () => import('@/components/views/chats/autoResponders/ChatAutoRespondersDetailed.vue'),
      },
      {
        name: 'base.chats.settings.autoResponders.whatsapp',
        path: 'whatsapp',
        meta: {
          title: 'Chats auto-responders whatsapp',
        },
        props: {
          title: 'Whatsapp auto-responders',
          icon: 'tmi-message-phone',
          hidePreview: true,
          hideMessageInfo: true,
        },
        component: () => import('@/components/views/chats/autoResponders/ChatAutoRespondersDetailed.vue'),
      },
      {
        name: 'base.chats.settings.autoResponders.facebook',
        path: 'facebook',
        meta: {
          title: 'Chats auto-responders facebook',
        },
        props: {
          title: 'Facebook messenger auto-responders',
          icon: 'tmi-message-meta',
          hidePreview: true,
          hideMessageInfo: true,
        },
        component: () => import('@/components/views/chats/autoResponders/ChatAutoRespondersDetailed.vue'),
      },
    ],
  },
  {
    name: 'base.chats.settings.10dlc',
    path: '10dlc',
    meta: {
      title: 'Chats 10DLC',
    },
    component: () => import('@/components/views/chats/Chat10DLC.vue'),
  },
  {
    name: 'base.chats.settings.tollFree',
    path: 'toll-free',
    meta: {
      title: 'Chats 10DLC toll free',
    },
    component: () => import('@/components/views/chats/ChatTollFree.vue'),
  },
  {
    name: 'base.chats.settings.general',
    path: 'general',
    meta: {
      title: 'Chats general',
    },
    component: () => import('@/components/views/chats/ChatGeneral.vue'),
  },
  {
    name: 'base.chats.settings.call',
    path: 'call',
    meta: {
      title: 'Chats call settings',
    },
    component: () => import('@/components/views/chats/ChatCallSettings.vue'),
  },
  {
    name: 'base.chats.settings.sender',
    path: 'sender',
    meta: {
      title: 'Chats sender settings',
    },
    component: () => import('@/components/views/chats/ChatSenderSettings.vue'),
  },
  {
    name: 'base.chats.settings.statuses',
    path: 'statuses',
    meta: {
      title: 'Chats statuses settings',
    },
    component: () => import('@/components/views/chats/ChatStatusesSettings.vue'),
  },
  {
    name: 'base.chats.settings.filteredViews',
    path: 'filtered-views',
    meta: {
      title: 'Chats filtered views settings',
    },
    component: PassThrough,
    redirect: { name: 'base.chats.settings.filteredViews.index' },
    children: [
      {
        name: 'base.chats.settings.filteredViews.index',
        path: '',
        meta: {
          title: 'Chats filtered views index',
        },
        component: () => import('@/components/views/chats/filteredViews/ChatFilteredViews.vue'),
      },
      {
        name: 'base.chats.settings.filteredViews.detailed',
        path: 'detailed',
        meta: {
          title: 'Chats filtered views settings detailed',
        },
        component: () => import('@/components/views/chats/filteredViews/ChatFilteredViewsDetailed.vue'),
      },
    ],
  },
]
