import type { RouteRecordRaw } from 'vue-router'

export const composeRoutes: Array<RouteRecordRaw> = [
  {
    name: 'base.compose.index',
    path: '',
    redirect: { name: 'base.compose.message' },
    component: () => import('@/components/views/compose/Compose.vue'),
    children: [
      {
        name: 'base.compose.largeSession',
        path: 'large',
        meta: {
          title: 'New text message large',
        },
        component: () => import('@/components/views/compose/ComposeLargeSession.vue'),
      },
      {
        name: 'base.compose.message',
        path: 'text-message',
        meta: {
          title: 'New text message',
        },
        component: () => import('@/components/views/compose/ComposeMessage.vue'),
      },
      {
        name: 'base.compose.speech',
        path: 'text-to-speech',
        meta: {
          title: 'New text to speech',
        },
        component: () => import('@/components/views/compose/ComposeSpeech.vue'),
      },
      {
        name: 'base.compose.broadcast',
        path: 'voice-broadcast',
        meta: {
          title: 'New voice broadcast',
        },
        component: () => import('@/components/views/compose/ComposeBroadcast.vue'),
      },
    ],
  },
  {
    name: 'base.compose.success',
    path: 'success',
    meta: {
      title: 'Sent text message details success',
    },
    component: () => import('@/components/views/compose/ComposeSuccess.vue'),
  },
  {
    name: 'base.compose.successBulk',
    path: 'success-bulk',
    meta: {
      title: 'Sent text message details bulk',
    },
    component: () => import('@/components/views/compose/ComposeSuccessBulk.vue'),
  },
]
